import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Util from '../../Classes/Util'
import { variables } from '../../Variables/Variables'
import { FiX } from 'react-icons/fi'

import empty from '../../Images/emptyPic.png'
import addCart from '../../Images/addCart.png'
import checkSquare from '../../Images/checkSquare.png'

const Wishlist = (props) => {
    const [step, setStep] = useState(0);
    const [wishlistItems, setWishlistItems] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const getWishlistItems = async () => {
        setIsLoading(true)
        if (sessionStorage.getItem("GuestWishlist") !== null) {
            var wishlistID = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            await fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + wishlistID, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    setWishlistItems(Result)
                })
        } else {
            var wishlistID = Util.decrypt(localStorage.getItem("WishList"));
            await fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + wishlistID, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    setWishlistItems(Result)
                })
        }
        setIsLoading(false)
    }

    const addToCart = async (ID) => {
        if (sessionStorage.getItem("Guest") !== null) {
            var CartID = Util.decrypt(sessionStorage.getItem("GuestCart"));
            // console.log(CartID)
            await fetch(variables.API_URL + "Cart/AddItemToCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cartId: CartID,
                    quantity: 1,
                    productId: ID,
                    sizeId: 0,
                    addOns: [],
                    unrequiredIngredients: [],
                })
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    getCartItems(CartID)
                })
        } else {
            var CartID = Util.decrypt(localStorage.getItem("Cart"));
            // console.log(CartID)
            await fetch(variables.API_URL + "Cart/AddItemToCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cartId: CartID,
                    quantity: 1,
                    productId: ID,
                    sizeId: 0,
                    addOns: [],
                    unrequiredIngredients: [],
                })
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    getCartItems(CartID)
                })
        }
    }

    const getCartItems = async (ID) => {
        await fetch(variables.API_URL + "Cart/GetCartItems/1/" + ID, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                props.setCartCount(Result.Count)
                props.setCartItems(Result.CartItems)
            })
    }

    const RemoveFromWishList = (ID) => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "Wishlist/RemoveItemFromWishlist/" + ID + "/" + WishListId, {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
            ).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount--;
                    props.setWishlistCount(wishListCount);

                    setTimeout(() => {
                        CheckWishList();
                        getWishlistItems();
                    }, 500);
                }
            });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "Wishlist/RemoveItemFromWishlist/" + ID + "/" + WishListId, {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
            ).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount--;
                    props.setWishlistCount(wishListCount);
                    setTimeout(() => {
                        CheckWishList();
                        getWishlistItems();
                    }, 500);
                }
            });
        }
    };

    const CheckWishList = () => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + WishListId, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    if (Result.length > 0) {
                        props.setWishlistItems(Result)
                        props.setWishlistCount(Result.length);
                    }
                });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + WishListId, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    if (Result.length > 0) {
                        props.setWishlistItems(Result)
                        props.setWishlistCount(Result.length);
                    }
                });
        }
    };

    useEffect(() => {
        getWishlistItems()
    }, [])
    return (
        <>
            {isLoading ? (
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div className='container-fluid Wishlist mb-5 mt-5 aftermobileheader'>
                    <div className='row text-center'>
                        <div className='col-12'>
                            <label className='mainTitle'>My Wishlist</label>
                        </div>
                    </div>
                    {/* <div className='d-lg-none d-xl-none d-md-block d-sm-block d-xs-block'>
                <div className="menuListScroll container-fluid">
                    <div className="row mt-5">
                        <div className="col-12 p-0">
                            <ul className="menulist">
                                <li className="active"><a>Menu</a></li>
                                <li><a>Special occassion</a></li>
                                <li><a>Business Lunch</a></li>
                                <li><a>Entertainment Packages</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
                    {wishlistItems.length === 0 ? (
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <img src={empty} alt="Empty" />
                            </div>
                        </div>
                    ) : (
                        <div className='row mt-lg-5 '>
                            {/* <div className='col-3 leftTabs d-none d-md-none d-lg-block d-xl-block'>
                        <ul className='tabsList'>
                            <li><button type='button' className='tabBtnActive' onClick={() => setStep(0)}>Order</button></li>
                            <li><button type='button' className='tabBtn' onClick={() => setStep(1)}>Special Occassions</button></li>
                            <li><button type='button' className='tabBtn' onClick={() => setStep(2)}>Entertainment</button></li>
                        </ul>
                    </div> */}
                            <div className='col-12 mb-5'>
                                <div className='row menuSectionInner'>
                                    <div className='container'>
                                        <div className='row'>
                                            {wishlistItems.map((item) => {
                                                return (
                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                        <div className='menuItem m-auto'>
                                                            <button className="wishlistBtn redBtn" type="button" onClick={() => RemoveFromWishList(item.ProductId)}><FiX /></button>
                                                            <Link to={"/MenuDetailed/" + item.ProductId + "/" + item.ProductName}>
                                                                <img src={variables.Photo_URL + item.Image} alt={item.ProductName} className="menuPicture" />
                                                                <h4>{item.ProductName}</h4>
                                                                <p>
                                                                    {item.Description}
                                                                </p>
                                                            </Link>
                                                            {props.cartItems.some((u) => u.ProductId == item.ProductId) ? (
                                                                <img src={checkSquare} className="btnAddCart" />
                                                            ) : (
                                                                <img src={addCart} className="btnAddCart" onClick={() => addToCart(item.ProductId)} />
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}
                </div>
            )}
        </>
    )
}

export default Wishlist