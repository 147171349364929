import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import slider from '../../Images/businessSlider.png'
import middleImg from '../../Images/businessMiddle.png'
import BusinesstArt from '../../Images/BusinesstArt.png'
import amBtn from '../../Images/amBtn.png'
import pmBtn from '../../Images/pmBtn.png'
import addSq from '../../Images/plusSquare.png'
import MinusSq from '../../Images/minusSquare.png'
import Util from '../../Classes/Util'
import { variables } from '../../Variables/Variables'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Business = () => {
    const [quantity, setQuantity] = useState(0);
    const navigate = useNavigate();
    const [day, setDay] = useState('');
    const [startingTime, setStartingTime] = useState('');
    const [endingTime, setEndingTime] = useState('');
    const [instructions, setInstructions] = useState('');

    const ManageQuantity = (i) => {
        let q = quantity;
        if (i == -1) {
            if (q > 0) {
                q--
            }
        }
        else {
            q++
        }
        setQuantity(q)
    }

    function bookAtable() {
        var ID = Util.decrypt(localStorage.getItem("Number"));
            fetch(variables.API_URL + "Booking/BookAnOccasion/" + ID, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    day: day,
                    startingTime: startingTime,
                    endingTime: endingTime,
                    numberOfPeople: quantity,
                    instructions: instructions,
                    bookingTypeId: 1
                }),
            }) .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return response.text().then((text) => {
                    throw new Error(text);
                });
            })
            .then((result) => {
                toast.success("Booked Successfully");
            }).catch((error) => {
                toast.error(error.message.replaceAll('"', ""));
            });
    }

    useEffect(() => {
        if (localStorage.getItem("Number") == null) {
            navigate("/Login")
        }
    }, [])



    return (
        <>
            <div className='container-fluid Business aftermobileheader'>
                <div className='row'>
                    <div className='col-12 p-0'>
                        <img src={slider} alt='Slider' />
                    </div>
                </div>

                <div className="menuListScroll mt-5 d-md-block d-lg-none d-xl-none d-xs-block d-sm-block">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 p-0">
                                <ul className="menulist">
                                    <li><label className="text-center"><Link to="/Menu">Menu</Link></label></li>
                                    <li><Link to="/SpecialOccassion">Special Occassion</Link> </li>
                                    <li className="active"><Link to="/BusinessLunch">Business Lunch</Link> </li>
                                    <li><Link to="/EntertainmentPackages">Entertainment Packages</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-lg-5'>
                    <div className='col-12 col-lg-6 m-auto'>
                        <p className='middleText'>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla. <br /><br />
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla.
                        </p>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <img src={middleImg} alt="business lunch" className='w-100' />
                    </div>
                </div>
                <div className='row mt-5 position-relative '>
                    <div className='col-lg-3 p-0 d-none d-lg-block'>
                        <img src={BusinesstArt} alt="Business" />
                    </div>
                    <div className='col-12 col-lg-9 '>
                        <div className='row '>
                            <div className='col-6 col-md-6 col-lg-6'>
                                <label className='headingLabel'>Book a table</label>
                            </div>
                            <div className='col-6 col-md-6 col-lg-6 m-auto'>
                                <div className='businessLine'></div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-5 col-md-5 col-lg-5 subTitleMargin'>
                                <label className='subTitle float-end'>to your liking</label>
                            </div>
                        </div>
                        <div className='row BookingBox'>
                            <div className='col-12 col-lg-6'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <label className='inputLabel'>Day:</label>
                                        <br />
                                        <input className='inputBox' type='date' onChange={(e) => setDay(e.target.value)} />
                                    </div>
                                </div>
                                <div className='row mt-5'>
                                    <div className='col-12'>
                                        <label className='inputLabel'>Starting Time:</label>
                                        <br />
                                        <input className='inputBox2' type='datetime-local' placeholder='00/00' onChange={(e) => setStartingTime(e.target.value)} />
                                        <button className='nextInput' type='button'>
                                            <img src={amBtn} alt="AM" />
                                        </button>
                                        <button className='nextInput' type='button'>
                                            <img src={pmBtn} alt="PM" />
                                        </button>
                                    </div>
                                </div>
                                <div className='row mt-5'>
                                    <div className='col-12'>
                                        <label className='inputLabel'>Ending Time:</label>
                                        <br />
                                        <input className='inputBox2' type='datetime-local' placeholder='00/00' onChange={(e) => setEndingTime(e.target.value)} />
                                        <button className='nextInput' type='button'>
                                            <img src={amBtn} alt="AM" />
                                        </button>
                                        <button className='nextInput' type='button'>
                                            <img src={pmBtn} alt="PM" />
                                        </button>
                                    </div>
                                </div>
                                <div className='row mt-5'>
                                    <div className='col-12'>
                                        <label className='inputLabel'>No. Of People:</label>
                                        <br />
                                        <label className='inputBox2 text-center'>{quantity}</label>
                                        <button className='nextInput' type='button' onClick={() => ManageQuantity(1)}>
                                            <img src={addSq} alt="add" />
                                        </button>
                                        <button className='nextInput' type='button' onClick={() => ManageQuantity(-1)}>
                                            <img src={MinusSq} alt="remove" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <label className='inputLabel'>Instructions:</label>
                                        <br />
                                        <textarea rows='8' cols='5' className='textareaBox' onChange={(e) => setInstructions(e.target.value)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <button type='button' className='bookBtn' onClick={bookAtable}>Book a table</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Business