import { Link } from "react-router-dom";
import logo from "../../Images/logo.png"
import {BsSearch, BsFillHeartFill, BsFillPersonFill} from 'react-icons/bs'
import {FaShoppingCart} from 'react-icons/fa'
const Header = (props) => {
    return (
        <>
            <header className="d-none d-lg-block d-xl-block largeHeader">
                <div className="container-fluid position-relative">
                    <div className="row">
                        <div className="col-lg-5 p-md-0">
                            <ul className="leftMenu p-0">
                                <li className={(window.location.href.indexOf("/Home") > -1) ? "active" : ""}>
                                    <Link to="/Home">Home</Link>
                                </li>
                                <li className={(window.location.href.indexOf("Story") > -1) ? "active" : ""}>
                                    <Link to="/Story">Story</Link>
                                </li>
                                <li className={(window.location.href.indexOf("Menu") > -1) ? "active" : ""}>
                                    <Link to="/Menu">Menu</Link>
                                </li>
                                <li className={(window.location.href.indexOf("BusinessLunch") > -1) ? "active" : ""}>
                                    <Link to="/BusinessLunch">Business Lunch</Link>
                                </li>
                                <li className={(window.location.href.indexOf("SpecialOccassion") > -1) ? "active" : ""}>
                                    <Link to="/SpecialOccassion">Special Occassion</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 p-md-0 text-center">
                            <img src={logo} alt="Bahia Khanoum Logo" className="logo" />
                        </div>
                        <div className="col-lg-5 p-md-0">
                            <ul className="rightMenu p-0">
                                <li className={(window.location.href.indexOf("EntertainmentPackages") > -1) ? "active" : ""}>
                                    <Link to="/EntertainmentPackages">Entertainment Packages</Link>
                                </li>
                                <li className={(window.location.href.indexOf("Booking") > -1) ? "active" : ""}>
                                    <Link to="/Booking">Booking</Link>
                                </li>
                                <li>
                                    <Link to="/Contact">Contact Us</Link>
                                </li>
                            </ul>
                            <ul className="rightNav">
                                <li className={(window.location.href.indexOf("Search") > -1) ? "active" : ""}><Link to="#"><BsSearch/></Link></li>
                                <li className={(window.location.href.indexOf("Wishlist") > -1) ? "active position-relative" : "position-relative"}><Link to="/Wishlist">
                                    <BsFillHeartFill/>
                                    <label className="cartNo">{props.wishlistCount}</label>
                                </Link></li>
                                <li className={(window.location.href.indexOf("Cart") > -1) ? "active position-relative" : "position-relative"}><Link to="/Cart">
                                    <FaShoppingCart/>
                                    <label className="cartNo">{props.cartCount}</label>
                                </Link></li>
                                {localStorage.getItem("Number") !== null ? <li className={(window.location.href.indexOf("Profile") > -1) ? "active" : ""}><Link to="/Profile"><BsFillPersonFill/></Link></li> 
                                : <li className={(window.location.href.indexOf("Profile") > -1) ? "active" : ""}><Link to="/Login"><BsFillPersonFill/></Link></li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <header className="d-md-block d-lg-none d-xl-none d-xs-block d-sm-block mobileheader">
                <div className="row">
                    <div className="col-12 text-center">
                        <img src={logo} alt="Bahia Khanoum Logo" className="logo" />
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header;