import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import './css/responsive.css'
import './css/mobile.css'
import Footer from './Pages/Layout/Footer';
import Header from './Pages/Layout/Header';
import AppRoute from './Variables/AppRoutes';
import { BrowserRouter } from 'react-router-dom'
import { variables } from './Variables/Variables';
import Util from './Classes/Util';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [cartCount, setCartCount] = useState(0);
  const [cartItems, setCartItems] = useState([{
    CartItemId: 0,
      ProductId: 0,
      ProductName: "",
      Description: "",
      Image: "",
      Quantity: 0,
      Price: 0,
      AddOns: [],
      UnrequiredIngredients: []
  }]);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [wishlistItems, setWishlistItems] = useState([])
  const [customerDetail, setCustomerDetail] = useState([])

  const createGuest = async () => {
    await fetch(variables.API_URL + "Guest/CreateGuest", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        sessionStorage.setItem("Guest", Util.encrypt(Result))
        getGuestCart(Result)
        getGuestWishlist(Result)
      })
  }

  const getGuestCart = async (ID) => {
    await fetch(variables.API_URL + "Cart/GetGuestCart/" + ID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        sessionStorage.setItem("GuestCart", Util.encrypt(Result))
        getCartItems(Result);
      })
  }

  const getCartItems = async (ID) => {
    await fetch(variables.API_URL + "Cart/GetCartItems/1/" + ID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        setCartCount(Result.Count)
        setCartItems(Result.CartItems)
      })
  }

  const getGuestWishlist = async (ID) => {
    await fetch(variables.API_URL + "Wishlist/GetGuestWishlist/" + ID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        sessionStorage.setItem("GuestWishlist", Util.encrypt(Result))
        getWishlistItems(Result)
      })
  }

  const getWishlistItems = async (ID) => {
    await fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + ID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

    })
      .then((Response) => {
        if (Response.ok) {
          return Response.json();
        }
      })
      .then((Result) => {
        setWishlistCount(Result.length)
        setWishlistItems(Result)
      })
  }

  const checkIfCustomerLoggedIn = () => {
    if (localStorage.getItem("Token") !== null) {
      var token = Util.decrypt(localStorage.getItem("Token"));
      var ID = Util.decrypt(localStorage.getItem("Number"));
      fetch(variables.API_URL + "Customer/CheckCustomerLoginToken", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: ID,
          customerToken: token,
        }),
      })
        .then((Response) => {
          if (Response.ok) {
            return Response.json();
          } else {
            localStorage.clear();
            checkIfCustomerLoggedIn();
          }
        })
        .then((Result) => {
          fetchCustomer(ID);
        });
    } else {
      localStorage.clear()
      if (sessionStorage.getItem("Guest") === null) {
        createGuest();
      } else {
        var GuestID = Util.decrypt(sessionStorage.getItem("Guest"));
        getGuestWishlist(GuestID);
        getGuestCart(GuestID);
      }
    }
  }

  const fetchCustomer = (ID) => {
    fetch(variables.API_URL + "Customer/GetCustomer/" + ID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        setCustomerDetail(Result);
      });
  };

  useEffect(() => {
    checkIfCustomerLoggedIn();
    if (localStorage.getItem("Cart") !== null) {
      getCartItems(Util.decrypt(localStorage.getItem("Cart")));
    }
    if (localStorage.getItem("WishList") !== null) {
      getWishlistItems(Util.decrypt(localStorage.getItem("WishList")));
    }
    
  }, [])

  return (
    <>
      <BrowserRouter>
        <Header cartCount={cartCount} wishlistCount={wishlistCount} />
        <AppRoute setCartCount={setCartCount} cartCount={cartCount} setCartItems={setCartItems} cartItems={cartItems} wishlistCount={wishlistCount} setWishlistCount={setWishlistCount} wishlistItems={wishlistItems} setWishlistItems={setWishlistItems} />
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <Footer />
      </BrowserRouter>

    </>
  );
}

export default App;
