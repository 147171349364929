import React, { useEffect, useState } from 'react'
import Util from '../../Classes/Util'
import profilepic from '../../Images/profilepic.png'
import { Link, useNavigate } from 'react-router-dom'
import { variables } from '../../Variables/Variables'
import { toast } from "react-toastify";
import axios from 'axios'
import rightLongArrow from '../../Images/rightLongArrow.png'
import xSquare from '../../Images/xSquare.png'
import "react-phone-number-input/style.css";
import PhoneInput, {
    isPossiblePhoneNumber,
    parsePhoneNumber,
} from "react-phone-number-input";

const Profile = (props) => {
    const [details, setDetails] = useState({
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: "",
        ProfilePicture: ""
    })
    const navigate = useNavigate();
    const [edit, setEdit] = useState(true)
    const [cities, setCities] = useState([])
    const [edits, setEdits] = useState({
        AddressBookId: 0,
        CityId: 0,
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        PostalCode: 0,
        StreetName: "",
        BuildingName: "",
        FloorNumber: 0,
        Nickname: ""
    })
    const [addAddress, setAddAddress] = useState(false)
    const [address, setAddress] = useState({
        CityId: 0,
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        PostalCode: 0,
        StreetName: "",
        BuildingName: "",
        FloorNumber: 0,
        Nickname: ""
    })
    const [allAddresses, setAllAddresses] = useState([{
        CityId: 0,
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        PostalCode: 0,
        StreetName: "",
        BuildingName: "",
        FloorNumber: 0,
        Nickname: "",
        CityName: ""
    }])
    const [editAddress, setEditAddress] = useState(0)
    const [openEdit, setOpenEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const getDetails = async () => {
        setIsLoading(true)
        const ID = Util.decrypt(localStorage.getItem("Number"));
        await fetch(variables.API_URL + "Profile/GetProfile/" + ID, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                setDetails(Result)
            })
    }

    const getAddresses = async () => {
        const ID = Util.decrypt(localStorage.getItem("Number"));
        await fetch(variables.API_URL + "AddressBook/GetAddressBooks/" + ID, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                setAllAddresses(Result)
            })
        setIsLoading(false)
    }

    const uploadImage = async (e) => {
        const formData = new FormData();
        formData.append("Image", e.target.files[0]);
        formData.append("FolderName", "ProfilePicture");

        const resImage = await axios.post(
            variables.API_URL + "Media/UploadImageToServer",
            formData
        );
        if (resImage.data !== "") {
            setDetails((prevState) => ({ ...prevState, ProfilePicture: resImage.data }))
        }
    }

    const editProfile = async () => {
        const ID = Util.decrypt(localStorage.getItem("Number"));
        await fetch(variables.API_URL + "Profile/UpdateProfile/" + ID, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                FirstName: details.FirstName,
                LastName: details.LastName,
                Email: details.Email,
                PhoneNumber: details.PhoneNumber,
                ProfilePicture: details.ProfilePicture
            })

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                toast.success(Result)
                setEdit(true)
            }).catch((error) => {
                toast.error(error.message.replaceAll('"', ""));
            });

    }

    const addAddressBook = async () => {
        const ID = Util.decrypt(localStorage.getItem("Number"));
        await fetch(variables.API_URL + "AddressBook/AddAddressBook", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                CustomerId: ID,
                CityId: address.CityId,
                FirstName: address.FirstName,
                LastName: address.LastName,
                PhoneNumber: address.PhoneNumber,
                PostalCode: address.PostalCode,
                StreetName: address.StreetName,
                BuildingName: address.BuildingName,
                FloorNumber: address.FloorNumber,
                Nickname: address.Nickname
            })

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                toast.success(Result)
                getAddresses()
            }).catch((error) => {
                toast.error(error.message.replaceAll('"', ""));
            });

    }

    const updateAddress = async () => {
        const ID = Util.decrypt(localStorage.getItem("Number"));
        await fetch(variables.API_URL + "AddressBook/UpdateAddressBook", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                AddressBookId: edits.AddressBookId,
                CustomerId: ID,
                CityId: edits.CityId,
                FirstName: edits.FirstName,
                LastName: edits.LastName,
                PhoneNumber: edits.PhoneNumber,
                PostalCode: edits.PostalCode,
                StreetName: edits.StreetName,
                BuildingName: edits.BuildingName,
                FloorNumber: edits.FloorNumber,
                Nickname: edits.Nickname
            })

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                toast.success(Result)
                getAddresses()
            }).catch((error) => {
                toast.error(error.message.replaceAll('"', ""));
            });

    }

    const deleteAddress = async (ID) => {
        await fetch(variables.API_URL + "AddressBook/DeleteAdressBook/" + ID, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                toast.success(Result)
                getAddresses()
            }).catch((error) => {
                toast.error(error.message.replaceAll('"', ""));
            });

    }

    const getCities = async () => {
        await fetch(variables.API_URL + "City/GetCitiesForDdl", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                setCities(Result)
            })
    }



    const LogOut = () => {
        localStorage.clear();
        window.location.href = "/";
    }

    useEffect(() => {
        if (localStorage.getItem("Number") == null) {
            navigate("/Login")
        } else {
            getDetails();
            getCities();
            getAddresses();
        }
    }, [])

    console.log(JSON.stringify({
        AddressBookId: edits.AddressBookId,
        CustomerId: 5,
        CityId: edits.CityId,
        FirstName: edits.FirstName,
        LastName: edits.LastName,
        PhoneNumber: edits.PhoneNumber,
        PostalCode: edits.PostalCode,
        StreetName: edits.StreetName,
        BuildingName: edits.BuildingName,
        FloorNumber: edits.FloorNumber,
        Nickname: edits.Nickname
    })
    )

    return (
        <>
            {isLoading ? (
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div className='container-fluid mt-lg-5 mb-5 Profile aftermobileheader'>
                    <div className='row'>
                        <div className='col-12 col-lg-3 text-center'>
                            <div className='row'>
                                <div className='col-12'>

                                    <label for="dp" >
                                        {details.ProfilePicture === "" ? <img src={profilepic} alt="profile picture" className='profilepic' /> : <img src={variables.Photo_URL + details.ProfilePicture} alt="profile picture" className='profilepic' />}
                                    </label>
                                    <input disabled={edit} className='d-none' id='dp' type="file" accept="image/*" onChange={(e) => uploadImage(e)} />
                                </div>
                            </div>
                            <div className='row d-none d-lg-block'>
                                <div className='col-12'>
                                    <Link to="/ChangePassword" className='topBtns'>Change Password</Link>
                                </div>
                            </div>
                            <div className='row d-lg-none'>
                                <div className='col-12'>
                                    <label className='nameMiddle'>{details.FirstName + " " + details.LastName}</label>
                                </div>
                            </div>
                            <div className='row d-lg-none'>
                                <div className='col-4'>
                                    <Link to="/Wishlist" className='topBtns'>Wishlist</Link>
                                </div>
                                <div className='col-4'>
                                    <button type='button' className='topBtns' onClick={() => setEdit(false)}>Edit Profile</button>
                                </div>
                                <div className='col-4'>
                                    <button type='button' className='topBtns' onClick={() => LogOut()}>Log out</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-9 m-auto'>
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 '>
                                    <label className='inputLabel'>First Name:</label>
                                    <br />
                                    <input type='text' className='inputBox' placeholder='Name' disabled={edit} value={details.FirstName} onChange={(e) => setDetails((prevState) => ({ ...prevState, FirstName: e.target.value, }))} />
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                                    <label className='inputLabel'>Last Name:</label>
                                    <br />
                                    <input type='text' className='inputBox' placeholder='Name' disabled={edit} value={details.LastName} onChange={(e) => setDetails((prevState) => ({ ...prevState, LastName: e.target.value, }))} />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='inputLabel'>Phone Number:</label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 pe-0'>
                                            <input type='text' className='inputBox' placeholder='12 345 678' disabled={edit} value={details.PhoneNumber} onChange={(e) => setDetails((prevState) => ({ ...prevState, PhoneNumber: e.target.value, }))} />
                                        </div>
                                        <div className='col-6 ps-0'>
                                            <button type='button' className='verfiyBtn'>Verify</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='inputLabel'>E-Mail:</label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {/* <div className='col-6 pe-0'> */}
                                        <div className='col-12'>
                                            <input type='text' className='inputBox' placeholder='example @ gmail .com' disabled={edit} value={details.Email} onChange={(e) => setDetails((prevState) => ({ ...prevState, Email: e.target.value, }))} />
                                        </div>
                                        {/* <div className='col-6 ps-0'>
                                    <button type='button' className='verfiyBtn'>Verify</button>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-5 '>
                        <div className='col-12 text-center'>
                            <div className='d-none d-lg-block'>
                                {edit === true ? <button type='button' className='editBtn' onClick={() => setEdit(false)}>Edit</button> : <button type='button' className='editBtn blueBtn' onClick={editProfile}>Change</button>}
                            </div>
                            <div className='d-lg-none'>
                                {edit === false && <button type='button' className='editBtn' onClick={editProfile}>Change</button>}
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                            <div className='row mb-3'>
                                <div className='col-12'>
                                    <label className='inputLabel'>My Location:</label>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6 pe-0'>
                                    <textarea className='textareaBox' rows='3' cols='4' placeholder='City , street name, Building Name or number, Floor Number' />
                                </div>
                                <div className='col-6 mt-2 ps-0'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462562.6509516108!2d54.94755498679835!3d25.07575945545691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2slb!4v1649758505875!5m2!1sen!2slb" height="150px" style={{ border: 0, width: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='mapstyle'></iframe>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-12 col-sm-12 col-md-6 col-lg-6'> 
                    <div className='row mb-3'>
                        <div className='col-12'>
                            <label className='inputLabel'>My Payment Method:</label>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-6'>
                            <label className='cardName'>Visa Card</label>
                            <br/>
                            <label className='cardInfo'>Abdul Aziz - ending with *****67</label>
                        </div>
                        <div className='col-6 m-auto'>
                            <button type='button' className='verfiyBtn'>CARD INFO</button>
                        </div>
                    </div>
                </div> */}
                    </div>
                    <div className='row mt-3'>
                        <div className='col-6'>
                            <label className='inputLabel'>Address Book:</label>
                        </div>
                        <div className='col-6 text-right'>
                            <button type='button' className='arrowBtn' onClick={() => setAddAddress(true)}>
                                <span>
                                    Add
                                </span>
                                <span>
                                    <img src={rightLongArrow} alt="Arrow" />
                                </span>
                            </button>
                        </div>
                    </div>
                    {addAddress == true && (
                        <form>
                            <div className='row mt-2'>
                                <div className='col-12 col-lg-6'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='addressName'>Add Address</label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-11'>
                                            <input required type='text' className='inputBox' placeholder='Address Nickname*' onChange={(e) => setAddress((prevState) => ({ ...prevState, Nickname: e.target.value, }))} />
                                        </div>
                                        <div className='col-1 text-end'>
                                            <img src={xSquare} className="removeBtn m-auto" alt="delete" onClick={() => {
                                                setAddress(
                                                    {
                                                        CityId: 0,
                                                        FirstName: "",
                                                        LastName: "",
                                                        PhoneNumber: "",
                                                        PostalCode: 0,
                                                        StreetName: "",
                                                        BuildingName: 0,
                                                        FloorNumber: 0,
                                                        Nickname: ""
                                                    }
                                                ); setAddAddress(false)
                                            }} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <input required type='text' className='inputBox' onChange={(e) => setAddress((prevState) => ({ ...prevState, FirstName: e.target.value, }))} placeholder='FirstName* ' />
                                        </div>
                                        <div className='col-6'>
                                            <input required type='text' className='inputBox' onChange={(e) => setAddress((prevState) => ({ ...prevState, LastName: e.target.value, }))} placeholder='LastName*' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            {/* <PhoneInput placeholder="Enter phone number" country="UAE" value={address.PhoneInput} className="inputBox" required={true} /> */}
                                            <input placeholder="Enter phone number" country="UAE" onChange={(e) => setAddress((prevState) => ({ ...prevState, PhoneNumber: e.target.value, }))} className="inputBox" required={true} />
                                        </div>
                                        <div className='col-6'>
                                            <select required className='inputBox' onChange={(e) => setAddress((prevState) => ({ ...prevState, CityId: parseInt(e.target.value,) }))}>
                                                <option selected disabled>-- Please choose a country --</option>
                                                {cities.map((u) => {
                                                    return (
                                                        <option key={u.CityId} value={u.CityId}>{u.CityName}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <input required type='text' className='inputBox' onChange={(e) => setAddress((prevState) => ({ ...prevState, StreetName: e.target.value, }))} placeholder='Street Name*' />
                                        </div>
                                        <div className='col-6'>
                                            <input required type='number' className='inputBox' onChange={(e) => setAddress((prevState) => ({ ...prevState, PostalCode: parseInt(e.target.value,) }))} placeholder='Postal Code*' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <input required type='text' className='inputBox' onChange={(e) => setAddress((prevState) => ({ ...prevState, BuildingName: e.target.value, }))} placeholder='Building Name* ' />
                                        </div>
                                        <div className='col-6'>
                                            <input required type='number' className='inputBox' onChange={(e) => setAddress((prevState) => ({ ...prevState, FloorNumber: parseInt(e.target.value,) }))} placeholder='Floor Number*' />
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-12 text-center'>
                                            <button className='editBtn blueBtn' type='button' onClick={addAddressBook}>Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                    <div className='row mt-2'>
                        {allAddresses.map((u) => {
                            return (
                                <div className='col-12 col-lg-6' key={u.addressBookId}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='addressName' onClick={() => {
                                                setEditAddress(u.AddressBookId); setEdits(
                                                    {
                                                        AddressBookId: u.AddressBookId,
                                                        CityId: u.CityId,
                                                        FirstName: u.FirstName,
                                                        LastName: u.LastName,
                                                        PhoneNumber: u.PhoneNumber,
                                                        PostalCode: u.PostalCode,
                                                        StreetName: u.StreetName,
                                                        BuildingName: u.BuildingName,
                                                        FloorNumber: u.FloorNumber,
                                                        Nickname: u.Nickname
                                                    }
                                                );
                                                setOpenEdit(true)
                                            }}>{u.Nickname}</label>
                                        </div>
                                    </div>


                                    {editAddress === u.AddressBookId ? (
                                        <form>
                                            <div className='row'>
                                                <div className='col-10 col-xl-11'>
                                                    <input required type='text' className='inputBox' value={edits.Nickname} placeholder='Address Nickname*' onChange={(e) => setEdits((prevState) => ({ ...prevState, Nickname: e.target.value, }))} />
                                                </div>
                                                <div className='col-2 col-xl-1 text-end m-auto'>
                                                    <img src={xSquare} className="removeBtn" alt="delete" onClick={() => deleteAddress(u.AddressBookId)} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input required type='text' className='inputBox' value={edits.FirstName} onChange={(e) => setEdits((prevState) => ({ ...prevState, FirstName: e.target.value, }))} placeholder='FirstName* ' />
                                                </div>
                                                <div className='col-6'>
                                                    <input required type='text' className='inputBox' value={edits.LastName} onChange={(e) => setEdits((prevState) => ({ ...prevState, LastName: e.target.value, }))} placeholder='LastName*' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    {/* <PhoneInput placeholder="Enter phone number" country="UAE" value={address.PhoneInput} className="inputBox" required={true} /> */}
                                                    <input placeholder="Enter phone number" country="UAE" onChange={(e) => setEdits((prevState) => ({ ...prevState, PhoneNumber: e.target.value, }))} value={edits.PhoneNumber} className="inputBox" required={true} />
                                                </div>
                                                <div className='col-6'>
                                                    <select required className='inputBox' value={edits.CityId} onChange={(e) => setAddress((prevState) => ({ ...prevState, CityId: parseInt(e.target.value,) }))}>
                                                        {cities.map((c) => {
                                                            return (
                                                                <option key={c.CityId} value={c.CityId}>{c.CityName}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input required type='text' className='inputBox' value={edits.StreetName} onChange={(e) => setEdits((prevState) => ({ ...prevState, StreetName: e.target.value, }))} placeholder='Street Name*' />
                                                </div>
                                                <div className='col-6'>
                                                    <input required type='number' className='inputBox' value={edits.PostalCode} onChange={(e) => setEdits((prevState) => ({ ...prevState, PostalCode: parseInt(e.target.value,) }))} placeholder='Postal Code*' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <input required type='text' className='inputBox' value={edits.BuildingName} onChange={(e) => setEdits((prevState) => ({ ...prevState, BuildingName: e.target.value, }))} placeholder='Building Name* ' />
                                                </div>
                                                <div className='col-6'>
                                                    <input required type='number' className='inputBox' value={edits.FloorNumber} onChange={(e) => setEdits((prevState) => ({ ...prevState, FloorNumber: parseInt(e.target.value,) }))} placeholder='Floor Number*' />
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col-12 text-center'>
                                                    <button className='editBtn blueBtn' type='button' onClick={updateAddress}>Edit</button>
                                                </div>
                                            </div>
                                        </form>
                                    ) : (
                                        <div className='row'>
                                            <div className='col-12'>
                                                <p className='addressText'>
                                                    {u.BuildingName + " " + u.FloorNumber}
                                                    <br />
                                                    {u.CityName + ", UAE"}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}

                    </div>
                    <div className='row mt-5'>
                        <div className='col-12 text-center'>

                            <button type='button' className='editBtn' onClick={LogOut}>Log out</button>

                        </div>
                    </div>
                </div >
            )}
        </>
    )
}

export default Profile