import Home from "../Pages/Home/UI/Home";
import {Routes,Route, Navigate} from 'react-router-dom'
import Story from "../Pages/Story/UI/Story";
import Contact from "../Pages/Contact/UI/Contact";
import MenuList from "../Pages/Menu/UI/MenuList";
import MenuDetailed from "../Pages/Menu/UI/MenuDetailed";
import Special from "../Pages/Booking/Special";
import Business from "../Pages/Booking/Business";
import Entertainment from "../Pages/Booking/Entertainment";
import Booking from "../Pages/Booking/Booking";
import Wishlist from "../Pages/User/Wishlist";
import Cart from "../Pages/User/Cart";
import Checkout from "../Pages/User/Checkout";
import Profile from "../Pages/User/Profile";
import LogIn from "../Pages/User/LogIn";
import Register from "../Pages/User/Register";
import ForgetPassword from "../Pages/User/ForgetPassword";
import ErrorPage from "../Pages/Home/UI/ErrorPage";
import VerfiyEmail from "../Pages/User/VerfiyEmail";
import ResetPassword from "../Pages/User/ResetPassword";
import ChangePassword from "../Pages/User/ChangePassword";
const AppRoute = (props) => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Navigate to="/Home" replace />} ></Route>
                <Route path="/Home" element={<Home setCartCount={props.setCartCount} cartCount = {props.cartCount} setCartItems={props.setCartItems} cartItems = {props.cartItems} wishlistCount = {props.wishlistCount} setWishlistCount = {props.setWishlistCount} wishlistItems = {props.wishlistItems} setWishlistItems = {props.setWishlistItems} />}></Route>
                <Route path="/Story" element={<Story />}></Route>
                <Route path="/Menu" element={<MenuList setCartCount={props.setCartCount} cartCount = {props.cartCount} setCartItems={props.setCartItems} cartItems = {props.cartItems} wishlistCount = {props.wishlistCount} setWishlistCount = {props.setWishlistCount} wishlistItems = {props.wishlistItems} setWishlistItems = {props.setWishlistItems} />}></Route>
                <Route path="/Contact" element={<Contact />}></Route>
                <Route path="/MenuDetailed/:id/:ProductName" element={<MenuDetailed setCartCount={props.setCartCount} cartCount = {props.cartCount} setCartItems={props.setCartItems} cartItems = {props.cartItems} wishlistCount = {props.wishlistCount} setWishlistCount = {props.setWishlistCount} wishlistItems = {props.wishlistItems} setWishlistItems = {props.setWishlistItems} />}></Route>
                <Route path="/BusinessLunch" element={<Business />}></Route>
                <Route path="/SpecialOccassion" element={<Special />}></Route>
                <Route path="/EntertainmentPackages" element={<Entertainment />}></Route>
                <Route path="/Booking" element={<Booking />}></Route>
                <Route path="/Wishlist" element={<Wishlist setCartCount={props.setCartCount} cartCount = {props.cartCount} setCartItems={props.setCartItems} cartItems = {props.cartItems} wishlistCount = {props.wishlistCount} setWishlistCount = {props.setWishlistCount} wishlistItems = {props.wishlistItems} setWishlistItems = {props.setWishlistItems} />}></Route>
                <Route path="/Cart" element={<Cart setCartCount={props.setCartCount} cartCount = {props.cartCount} setCartItems={props.setCartItems} cartItems = {props.cartItems} wishlistCount = {props.wishlistCount} setWishlistCount = {props.setWishlistCount} wishlistItems = {props.wishlistItems} setWishlistItems = {props.setWishlistItems} />}></Route>
                <Route path="/Checkout" element={<Checkout setCartCount={props.setCartCount} cartCount = {props.cartCount} setCartItems={props.setCartItems} cartItems = {props.cartItems} wishlistCount = {props.wishlistCount} setWishlistCount = {props.setWishlistCount} wishlistItems = {props.wishlistItems} setWishlistItems = {props.setWishlistItems} />}></Route>
                <Route path="/Profile" element={<Profile setCartCount={props.setCartCount} cartCount = {props.cartCount} setCartItems={props.setCartItems} cartItems = {props.cartItems} wishlistCount = {props.wishlistCount} setWishlistCount = {props.setWishlistCount} wishlistItems = {props.wishlistItems} setWishlistItems = {props.setWishlistItems} />}></Route>
                <Route path="/Login" element={<LogIn />}></Route>
                <Route path="/Register" element={<Register />}></Route>
                <Route path="/ForgetPassword" element={<ForgetPassword />}></Route>
                <Route path="/ResetPassword" element={<ResetPassword />}></Route>
                <Route path="/ChangePassword" element={<ChangePassword />}></Route>
                <Route path="/VerifyEmail" element={<VerfiyEmail />}></Route>
                <Route path="*" element={<ErrorPage />}></Route>
            </Routes>
        </>
    )
}
export default AppRoute;