import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import menuItem1 from '../../Images/menuItem1.png'
import menuItem2 from '../../Images/menuItem2.png'
import menuItem3 from '../../Images/menuItem3.png'
import menuItem4 from '../../Images/menuItem4.png'
import empty from '../../Images/emptyPic.png'
import addCart from '../../Images/addCart.png'
import Util from '../../Classes/Util'
import { variables } from '../../Variables/Variables'
import { FiX } from 'react-icons/fi'

const Cart = (props) => {
    const [step, setStep] = useState(0);
    const [CartItems, setCartItems] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false);

    const getCartItems = async () => {
        setIsLoading(true)
        if (sessionStorage.getItem("Guest") !== null) {
            var CartID = Util.decrypt(sessionStorage.getItem("GuestCart"));
            await fetch(variables.API_URL + "Cart/GetCartItems/1/" + CartID, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    setCartItems(Result.CartItems)
                    setTotal(Result.Total)
                })
        }
        else {
            var CartID = Util.decrypt(localStorage.getItem("Cart"));
            await fetch(variables.API_URL + "Cart/GetCartItems/1/" + CartID, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    setCartItems(Result.CartItems)
                    setTotal(Result.Total)
                })
        }
        setIsLoading(false)
    }
    const RemoveFromCart = (ID) => {
        if (localStorage.getItem("Cart") !== null) {
            var CartID = Util.decrypt(localStorage.getItem("Cart"));
            fetch(variables.API_URL + "Cart/RemoveItemFromCart/" + ID + "/" + CartID, {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
            ).then((Response) => {
                if (Response.ok) {
                    var cartCount = props.cartCount;
                    cartCount--;
                    props.setCartCount(cartCount);
                    setTimeout(() => {
                        getCartItems()
                    }, 400);
                }
            });
        } else {
            var CartID = Util.decrypt(sessionStorage.getItem("GuestCart"));
            fetch(variables.API_URL + "Cart/RemoveItemFromCart/" + ID + "/" + CartID, {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
            ).then((Response) => {
                if (Response.ok) {
                    var cartCount = props.cartCount;
                    cartCount--;
                    props.setCartCount(cartCount);
                    setTimeout(() => {
                        getCartItems()
                    }, 400);
                }
            });
        }
    }

    useEffect(() => {
        getCartItems()
    }, [])

    // console.log(CartItems.length)

    return (
        <>
            {isLoading ? (
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <div className='container-fluid Cart mb-5 mt-5 aftermobileheader'>
                    <div className='row text-center'>
                        <div className='col-12'>
                            <label className='mainTitle'>My Cart</label>
                        </div>
                    </div>
                    {/* <div className='d-lg-none d-xl-none d-md-block d-sm-block d-xs-block'>
                        <div className="menuListScroll container-fluid">
                            <div className="row mt-5">
                                <div className="col-12 p-0">
                                    <ul className="menulist">
                                        <li className="active"><a>Menu</a></li>
                                        <li><a>Special occassion</a></li>
                                        <li><a>Business Lunch</a></li>
                                        <li><a>Entertainment Packages</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {CartItems.length === 0 ? (
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <img src={empty} alt="Empty" />
                            </div>
                        </div>
                    ) : (
                        <div className='row mt-5 '>
                            {/* <div className='col-3 leftTabs d-none d-md-none d-lg-block d-xl-block'>
                                <ul className='tabsList'>
                                    <li><button type='button' className='tabBtnActive' onClick={() => setStep(0)}>Order</button></li>
                                    <li><button type='button' className='tabBtn' onClick={() => setStep(1)}>Special Occassions</button></li>
                                    <li><button type='button' className='tabBtn' onClick={() => setStep(2)}>Entertainment</button></li>
                                </ul>
                            </div> */}
                            <div className='col-12'>
                                <div className='row menuSectionInner'>
                                    <div className='container'>
                                        <div className='row'>
                                            {CartItems.map((item) => {
                                                return (
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                                        <div className='menuItem'>
                                                            <button className="wishlistBtn redBtn" type="button" onClick={() => RemoveFromCart(item.ProductId)}><FiX /></button>
                                                            <Link to={"/MenuDetailed/" + item.ProductId + "/" + item.ProductName}>
                                                                <img src={variables.Photo_URL + item.Image} alt={item.ProductName} className="menuPicture" />

                                                                <h4>{item.ProductName}</h4>
                                                                <p>
                                                                    {item.Description}
                                                                </p>
                                                            </Link>
                                                            <img src={addCart} className="btnAddCart" />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-12 col-xl-6'>
                                        <div className='totalInfo'>
                                            {CartItems.map((item) => {
                                                return (
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-4 col-lg-4'>
                                                                <label className='itemName'>{item.ProductName}</label>
                                                            </div>
                                                            <div className='col-4 col-lg-5'>
                                                                <hr className='itemLine' />
                                                            </div>
                                                            <div className='col-4 col-lg-3'>
                                                                <label className='itemPrice'>{item.Price} A.E.D</label>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <ul className='finalCart'>
                                                                    <li><strong>Quantity:</strong> {item.Quantity}</li>
                                                                    <li><strong>Size:</strong> {item.SizeName}</li>
                                                                    {item.AddOns.length !== 0 && (
                                                                        <li><strong>AddOns:</strong>
                                                                            <ul className='addons'>
                                                                                {item.AddOns.map((adds) => {
                                                                                    return (
                                                                                        <li>
                                                                                            {adds.Name + " "} <span style={{ color: "red" }}>{adds.ExtraPrice + " A.E.D."}</span>
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </li>
                                                                    )}
                                                                    {item.UnrequiredIngredients.length !== 0 && (
                                                                        <li><strong>Removed:</strong>
                                                                            <ul className='addons'>
                                                                                {item.UnrequiredIngredients.map((adds) => {
                                                                                    return (
                                                                                        <li>
                                                                                            {adds.Name + " "}
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                            <hr />
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <label className='totalTitle'>Total Sum</label>
                                                </div>
                                                <div className='col-6'>
                                                    <label className='totalAmount'>{total} A.E.D</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-xl-6 buttonsPadding'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <button type='button' className='continueBtn'>Continue Exploring</button>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-12'>
                                                <Link to="/Checkout" type='button' className='checkoutBtn'>Go to checkout</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}
                </div>
            )}
        </>
    )
}

export default Cart