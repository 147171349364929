import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom'
import MenuItem from '../../../Images/MenuItem.png'
import SubImg1 from '../../../Images/MenuItemSmall1.png'
import SubImg2 from '../../../Images/MenuItemSmall2.png'
import SubImg3 from '../../../Images/MenuItemSmall3.png'
import SubImg4 from '../../../Images/MenuItemSmall4.png'
import addSq from '../../../Images/plusSquare.png'
import MinusSq from '../../../Images/minusSquare.png'
import reviewpic from '../../../Images/reviewpic.png'
import menuItem1 from '../../../Images/menuItem1.png'
import menuItem2 from '../../../Images/menuItem2.png'
import menuItem3 from '../../../Images/menuItem3.png'
import menuItem4 from '../../../Images/menuItem4.png'
import addCart from '../../../Images/addCart.png'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { variables } from "../../../Variables/Variables";
import Util from "../../../Classes/Util";
import { toast } from "react-toastify";
import { BsHeart, BsHeartFill } from "react-icons/bs";


const MenuDetailed = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [details, setDetails] = useState({
        Description: "",
        Image: "",
        Ingredients: [{
            IngredientId: 0,
            IngredientName: ""
        }],
        UnrequiredIngredients: [{
            IngredientId: 0,
            Name: ""
        }],
        AddOns: [{
            IngredientId: 0,
            Name: "",
            ExtraPrice: 0
        }],
        Sizes: [{
            SizeId: 0,
            SizeName: "",
            Price: 0
        }],
        ProductName: "",
        RegularPrice: 0,
        SalePrice: 0,
    })
    const id = useParams();
    const [addon, setAddon] = useState([])
    const [removes, setRemoves] = useState([])
    const [sizeId, setSizeId] = useState(0)

    const getDetails = async () => {
        setIsLoading(true)
        await fetch(variables.API_URL + "Product/GetProductByLanguageId/" + id.id + "/1", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                setDetails(Result)
            })
        setIsLoading(false)
    }

    const ManageQuantity = (i) => {
        let q = quantity;
        if (i == -1) {
            if (q > 1) {
                q--
            }
        }
        else {
            q++
        }
        setQuantity(q)
    }

    const addToCart = async () => {
        if (sessionStorage.getItem("Guest") !== null) {
            var CartID = Util.decrypt(sessionStorage.getItem("GuestCart"));
            await fetch(variables.API_URL + "Cart/AddItemToCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cartId: CartID,
                    quantity: quantity,
                    productId: id.id,
                    sizeId: sizeId,
                    addOns: addon,
                    unrequiredIngredients: removes
                })
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    getCartItems(CartID)
                })
        } else {
        }
        var CartID = Util.decrypt(localStorage.getItem("Cart"));
        await fetch(variables.API_URL + "Cart/AddItemToCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cartId: CartID,
                quantity: quantity,
                productId: id.id,
                addOns: addon,
                unrequiredIngredients: removes
            })
        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                getCartItems(CartID)
                toast.success(Result)
            })

    }



    const getCartItems = async (ID) => {
        await fetch(variables.API_URL + "Cart/GetCartItems/1/" + ID, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                props.setCartCount(Result.Count)
                props.setCartItems(Result.CartItems)
            })
    }

    function removeItem(ID) {
        var array = [...details.UnrequiredIngredients];
        var removeInte = [...removes]
        removeInte.push({ IngredientId: ID })
        array = array.filter((x) => x.IngredientId !== ID)
        setRemoves(removeInte)
        setDetails((prev) => ({
            ...prev,
            UnrequiredIngredients: array,
        }))
    }

    function removeAddon(ID) {
        var array = [...addon];
        array = array.filter((x) => x.IngredientId !== ID)
        setAddon(array)
    }

    function addItem(ID) {
        if (addon.some((u) => u.IngredientId == ID)) {
            toast.error("Item Already Added")
        } else {
            var addInte = [...addon]
            addInte.push({ IngredientId: ID })
            setAddon(addInte)
            toast.success("Added Successfully")
        }
    }

    function addUnReq(ID) {
        var addInte = [...details.UnrequiredIngredients]
        addInte.push({ IngredientId: ID })
        setDetails((prev) => ({
            ...prev,
            UnrequiredIngredients: addInte,
        }))
        var array = [...removes];
        array = array.filter((x) => x.IngredientId !== ID)
        setRemoves(array)
    }

    const addToWishlist = () => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "WishList/AddItemToWishList", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    wishlistId: WishListId,
                    productId: id.id,
                }),
            }).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount++;
                    props.setWishlistCount(wishListCount);
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "WishList/AddItemToWishList", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    wishlistId: WishListId,
                    productId: id.id,
                }),
            }).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount++;
                    props.setWishlistCount(wishListCount);
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        }
    };

    const RemoveFromWishList = () => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "Wishlist/RemoveItemFromWishlist/" + id.id + "/" + WishListId, {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
            ).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount--;
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "Wishlist/RemoveItemFromWishlist/" + id.id + "/" + WishListId, {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
            ).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount--;
                    props.setWishlistCount(wishListCount);
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        }
    };

    const CheckWishList = () => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + WishListId, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    if (Result.length > 0) {
                        props.setWishlistItems(Result)
                        props.setWishlistCount(Result.length);
                    }
                });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + WishListId, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    if (Result.length > 0) {
                        props.setWishlistItems(Result)
                        props.setWishlistCount(Result.length);
                    }
                });
        }
    };

    useEffect(() => {
        getDetails()
        setTimeout(() => {
            window.carouselFunction();
        }, 1500);
    }, [])

    
    {console.log("unreq", details.UnrequiredIngredients)}
    {console.log("addon", addon)}
    {console.log("removes", removes)}
    return (
        <>
            {isLoading ? (
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <>
                    <div className="container-fluid MenuDetails mb-5 aftermobileheader">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="row">
                                    <div className="col-12 col-lg-12 p-0 position-relative">
                                        <img src={variables.Photo_URL + details.Image} alt="Menu Item" />
                                        {props.wishlistItems.some((u) => u.ProductId == id.id) == true ?
                                            <button className="wishlistBtn redBtn" type="button" onClick={() => RemoveFromWishList()}><BsHeartFill /></button>
                                            : <button className="wishlistBtn" type="button" onClick={() => addToWishlist()}><BsHeart /></button>
                                        }
                                    </div>
                                    {/* <div className="col-2 col-lg-12 p-0">
                                <ul className="subImages text-center mt-lg-2 p-0">
                                    <li>
                                        <img src={SubImg1} alt="Sub Img" />
                                    </li>
                                    <li>
                                        <img src={SubImg2} alt="Sub Img" />
                                    </li>
                                    <li>
                                        <img src={SubImg3} alt="Sub Img" />
                                    </li>
                                    <li>
                                        <img src={SubImg4} alt="Sub Img" />
                                    </li>
                                </ul>
                            </div> */}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 detailsPadding">
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <label className="itemName">{details.ProductName}</label>
                                    </div>
                                </div>
                                {details.SalePrice !== 0 ? (
                                    <>
                                        <div className="row mt-2">
                                            <div className="col-12 ">
                                                <strike className="itemOldPrice float-end">{details.RegularPrice + " A.E.D."}</strike>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-7 col-sm-8 col-md-8 col-lg-7 col-xl-8">
                                                <div className="priceLine"></div>
                                            </div>
                                            <div className="col-5 col-sm-4 col-md-4 col-lg-5 col-xl-4">
                                                <label className="itemPrice">{details.SalePrice + " A.E.D."}</label>
                                            </div>
                                        </div>

                                    </>
                                ) : (
                                    <div className="row mt-2">
                                        <div className="col-7 col-sm-8 col-md-8 col-lg-7 col-xl-8">
                                            <div className="priceLine"></div>
                                        </div>
                                        <div className="col-5 col-sm-4 col-md-4 col-lg-5 col-xl-4">
                                            <label className="itemPrice">{details.RegularPrice + " A.E.D."}</label>
                                        </div>
                                    </div>
                                )}

                                <div className="row">
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3">
                                        <label className="detailTitle">Description:</label>
                                    </div>
                                    <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-9">
                                        <p className="detailInfo">{details.Description} </p>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3">
                                        <label className="detailTitle">Ingredients:</label>
                                    </div>
                                    <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-9">
                                        {details.Ingredients.map((i) => {
                                            return (
                                                <div className="row" key={i.IngredientId}>
                                                    <div className="col-5">
                                                        <label className="detailInfo">{i.IngredientName}</label>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {details.AddOns.length !== 0 && (
                                    <div className="row mt-2">
                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3">
                                            <label className="detailTitle">Add ons:</label>
                                        </div>
                                        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-9">
                                            {details.AddOns.map((i) => {
                                                return (
                                                    <div className="row" key={i.IngredientId}>
                                                        <div className="col-5">
                                                            <label className="detailInfo">{i.Name} </label>
                                                            <label className="detailNote">{"extra: " + i.ExtraPrice + ' A.E.D.'}</label>
                                                        </div>
                                                        <div className="col-3 m-auto">
                                                            <div className="manageItemBorder">
                                                                <button type="button" className="addItem" onClick={() => addItem(i.IngredientId)} >Add</button>
                                                            </div>
                                                        </div>
                                                        {addon.filter((x) => x.IngredientId == i.IngredientId).map((r) => {
                                                            return (
                                                                <div className="col-3 m-auto">
                                                                    <div className="manageItemBorder">
                                                                        <button type="button" className="removeItem" onClick={() => removeAddon(i.IngredientId)}>Remove</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                                 {details.Sizes.length !== 0 && (
                                    <div className="row mt-2">
                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3">
                                            <label className="detailTitle">Removable Ingredients:</label>
                                        </div>
                                        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-9">
                                            {details.UnrequiredIngredients.map((i) => {
                                                return (
                                                    <div className="row" key={i.IngredientId}>
                                                        <div className="col-5">
                                                            <label className="detailInfo">{i.Name}</label>
                                                        </div>
                                                        {removes.filter((x) => x.IngredientId == i.IngredientId).map((r) => {
                                                            return (
                                                                <div className="col-3 m-auto">
                                                                    <div className="manageItemBorder">
                                                                        <button type="button" className="addItem" onClick={() => addUnReq(i.IngredientId)}>Add</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        <div className="col-3 m-auto">
                                                            <div className="manageItemBorder">
                                                                <button type="button" className="removeItem" onClick={() => removeItem(i.IngredientId)}>Remove</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                 )}
                                 {details.AddOns.length !== 0 && (
                                    <div className="row mt-2">
                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3">
                                            <label className="detailTitle">Sizes:</label>
                                        </div>
                                        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-9">
                                            <div className="row">
                                                {details.Sizes.map((i) => {
                                                    return (
                                                        <div className="col-4" key={i.SizeId}>
                                                            <div className="sizePadding">
                                                                <div className="manageItemBorder">
                                                                    <button type="button" className="addItem" onClick={() => setSizeId(i.SizeId)}>{i.SizeName}</button>
                                                                </div>
                                                                <label className="sizePrice">{i.Price + " A.E.D."}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                 )}
                                {/* <div className="row mt-4">
                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3">
                                <label className="detailTitle">Replace:</label>
                                <br />
                                <span className="detailNote">(Lemon Dressing)</span>
                            </div>
                            <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-9">
                                <div className="row">
                                    <div className="col-9 m-auto">
                                        <label className="detailInfo">Balsmary Sauce</label>
                                    </div>
                                    <div className="col-3 m-auto">
                                        <div className="manageItemBorder">
                                            <button type="button" className="addItem">Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-9 m-auto">
                                        <label className="detailInfo">Progermenate Sauce</label>
                                    </div>
                                    <div className="col-3 m-auto">
                                        <div className="manageItemBorder">
                                            <button type="button" className="addItem">Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-3">
                                <label className="detailTitle">Replace:</label>
                                <br />
                                <span className="detailNote">(Olive Oil)</span>
                            </div>
                            <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-9">
                                <div className="row">
                                    <div className="col-9 m-auto">
                                        <label className="detailInfo">Vinegar</label>
                                    </div>
                                    <div className="col-3 m-auto">
                                        <div className="manageItemBorder">
                                            <button type="button" className="addItem">Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-9 m-auto">
                                        <label className="detailInfo">Seasame Oil</label>
                                    </div>
                                    <div className="col-3 m-auto">
                                        <div className="manageItemBorder">
                                            <button type="button" className="addItem">Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                <div className="row mt-5">
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-3 p-0">
                                                <button type="button" className="quantityBtn float-end" onClick={() => ManageQuantity(-1)}> <img src={MinusSq} alt="minus" /></button>
                                            </div>
                                            <div className="col-6 text-center">
                                                <label className="qunatityLabel">{quantity}</label>
                                            </div>
                                            <div className="col-3 p-0">
                                                <button type="button" className="quantityBtn" onClick={() => ManageQuantity(1)}> <img src={addSq} alt="plus" /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <button className="cartItem" type="button" onClick={() => addToCart()}>Add to cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5 ">
                            <div className="col-12 SuggestedPadding">
                                <label className="reviewTitle">REVIEWS</label>
                            </div>
                            <div className="col-12">
                                <div className="owl-carousel owl-theme reviewsCarouselMD">
                                    <div class="item">
                                        <div className="reviewBox">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="personName">Person Name</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <p className="reviewText">
                                                        Lorem Ipsum Dolor Sit Amet,
                                                        Consectetuer Adipiscing Elit,
                                                        Sed Diam Nonummy Nibh.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="rates p-0">
                                                        <li><AiFillStar /></li>
                                                        <li><AiFillStar /></li>
                                                        <li><AiFillStar /></li>
                                                        <li><AiOutlineStar /></li>
                                                        <li><AiOutlineStar /></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <img src={reviewpic} className="reviewImg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div className="reviewBox">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="personName">Person Name</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <p className="reviewText">
                                                        Lorem Ipsum Dolor Sit Amet,
                                                        Consectetuer Adipiscing Elit,
                                                        Sed Diam Nonummy Nibh.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <ul className="rates p-0">
                                                        <li><AiFillStar /></li>
                                                        <li><AiFillStar /></li>
                                                        <li><AiFillStar /></li>
                                                        <li><AiOutlineStar /></li>
                                                        <li><AiOutlineStar /></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <img src={reviewpic} className="reviewImg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='menuSection SuggestedPadding'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className="itemName">suggested iTEMS</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='owl-carousel owl-theme suggestedCarousel'>
                                        <div class='item'>
                                            <div className='menuItem'>
                                                <Link to="/">
                                                    <img src={menuItem1} alt="MenuItem 1" />
                                                    <h4>BRAZEEQ</h4>
                                                    <p>
                                                        Lorem ipsum lorem ipsum
                                                        ignie lorem si pisetg
                                                    </p>
                                                </Link>
                                                <img src={addCart} className="btnAddCart" />
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div className='menuItem'>
                                                <Link to="/">
                                                    <img src={menuItem2} alt="MenuItem 1" />
                                                    <h4>Waraq Anab</h4>
                                                    <p>
                                                        Lorem ipsum lorem ipsum
                                                        ignie lorem si pisetg
                                                    </p>
                                                </Link>
                                                <img src={addCart} className="btnAddCart" />
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div className='menuItem'>
                                                <Link to="/">
                                                    <img src={menuItem3} alt="MenuItem 1" />
                                                    <h4>fried cauliflower</h4>
                                                    <p>
                                                        Lorem ipsum lorem ipsum
                                                        ignie lorem si pisetg
                                                    </p>
                                                </Link>
                                                <img src={addCart} className="btnAddCart" />
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div className='menuItem'>
                                                <Link to="/">
                                                    <img src={menuItem4} alt="MenuItem 1" />
                                                    <h4>Fatteh B lABAN</h4>
                                                    <p>
                                                        Lorem ipsum lorem ipsum
                                                        ignie lorem si pisetg
                                                    </p>
                                                </Link>
                                                <img src={addCart} className="btnAddCart" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default MenuDetailed;