var key = "kNMG1HX4GMUIpOVIS0UBTzWREQkPApTt";
var CryptoJS = require("crypto-js");
class Util {

    constructor() {
        this.key = key;
    }

    encrypt(text) {
        var encryptedtext = CryptoJS.AES.encrypt(JSON.stringify(text), this.key).toString();
        return encryptedtext;
    }

    decrypt(encryptedText) {
        var bytes = CryptoJS.AES.decrypt(encryptedText, this.key);
        var decryptedText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedText;
    }

}

export default new Util();