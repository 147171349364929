import React, { useState } from 'react'
import { variables } from '../../Variables/Variables';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Util from '../../Classes/Util';

const ChangePassword = () => {
    const [pass, setPass] = useState("");
    const [cPass, setcPass] = useState("");
    const [old, setOld] = useState("");
    const navigate = useNavigate()

    function isValid() {
        if (
            old === "" ||
            pass === "" ||
            cPass === ""
        ) {
            toast.error("please fill required fields");
            return false;
        }
        else {
            var strongPasswordRegex = new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
            );
            var validpassword = strongPasswordRegex.test(pass);
            if (!validpassword) {
                toast.error("Password must be minimum 8 characters consisting of numbers, uppercase and lowercase letters and Symbols")
                return false;
            }
            if (pass !== cPass) {
                toast.error("Passwords should be the same.")
            }
        }
        return true;
    }
    const forgetPass = async () => {
        const email = Util.decrypt(localStorage.getItem("User"))
        if (isValid()){
            await fetch(variables.API_URL + "Customer/CustomerResetPassword/", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    Email: email,
                    oldPassword: old,
                    newPassword: pass
                })
    
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    toast.success("Your password has been changed.")
                    navigate("/Profile")
                }).catch((error) => {
                    toast.error(error.message.replaceAll('"', ""));
                });
        }
    }
  return (
    <>
         <div className='container-fluid Forget aftermobileheader'>
            <div className='row'>
                <div className='col-12 text-center'>
                    <label className='mainTitle'>Change password</label>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <label className='inputLabel'>Old Password:</label>
                    <br/>
                    <input type='password' className='inputBox' placeholder='Old Password' onChange={(e) => setOld(e.target.value)}/>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <label className='inputLabel'>New Password:</label>
                    <br/>
                    <input type='password' className='inputBox' placeholder='New Password' onChange={(e) => setPass(e.target.value)}/>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <label className='inputLabel'>Confirm Password:</label>
                    <br/>
                    <input type='password' className='inputBox' placeholder='Confirm Password' onChange={(e) => setcPass(e.target.value)}/>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-12'>
                    <button type='button' className='confirmBtn' onClick={forgetPass}>Reset Password</button>
                </div>
            </div> 
        </div>
    </>
  )
}

export default ChangePassword