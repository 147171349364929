import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import BusinesstArt from '../../Images/BusinesstArt.png'
import BusinesstArt2 from '../../Images/careerArt.png'
import amBtn from '../../Images/amBtn.png'
import pmBtn from '../../Images/pmBtn.png'
import addSq from '../../Images/plusSquare.png'
import MinusSq from '../../Images/minusSquare.png'
import special1 from '../../Images/special1.png'
import special2 from '../../Images/special2.png'
import special3 from '../../Images/special3.png'
import titleBg from '../../Images/titleBgMenu.png'
import { FiX } from 'react-icons/fi'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Util from '../../Classes/Util'
import { variables } from '../../Variables/Variables'

const Special = () => {
  const [quantity, setQuantity] = useState(0);
  const [showModel, setShowModel] = useState(false);
  const [bookType, setBookType] = useState(0)
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [day, setDay] = useState('');
  const [startingTime, setStartingTime] = useState('');
  const [endingTime, setEndingTime] = useState('');
  const [instructions, setInstructions] = useState('');

  const ManageQuantity = (i) => {
    let q = quantity;
    if (i == -1) {
      if (q > 0) {
        q--
      }
    }
    else {
      q++
    }
    setQuantity(q)
  }

  function isValid() {
    if (
      quantity === 0 ||
      day === '' ||
      startingTime === '' ||
      endingTime === '' ||
      instructions === ''
    ) {
      toast.error("please fill required fields");
      return false;
    }
    return true
  }

  function bookAtable() {
    if (isValid()) {
      var ID = Util.decrypt(localStorage.getItem("Number"));
      fetch(variables.API_URL + "Booking/BookAnOccasion/" + ID, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          day: day,
          startingTime: startingTime,
          endingTime: endingTime,
          numberOfPeople: quantity,
          instructions: instructions,
          bookingTypeId: bookType
        }),
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
        .then((result) => {
          toast.success("Booked Successfully");
        }).catch((error) => {
          toast.error(error.message.replaceAll('"', ""));
        }); 
    }
  }

  useEffect(() => {
    if (localStorage.getItem("Number") == null) {
      navigate("/Login")
    }
    setTimeout(() => {
      window.carouselFunction();
    }, 1500);
  }, [])
  return (
    <>
      <div className="menuTitleBar d-md-block d-lg-none d-xl-none d-xs-block d-sm-block aftermobileheader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <img src={titleBg} alt="Bahia Menu Title" />
            </div>
          </div>
        </div>
      </div>
      <div className="menuListScroll mt-5 d-md-block d-lg-none d-xl-none d-xs-block d-sm-block">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <ul className="menulist">
                <li><label className="text-center"><Link to="/Menu">Menu</Link></label></li>
                <li className="active"><Link to="/SpecialOccassion">Special Occassion</Link> </li>
                <li><Link to="/BusinessLunch">Business Lunch</Link> </li>
                <li><Link to="/EntertainmentPackages">Entertainment Packages</Link> </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid Business'>
        <div className='specialSection'>
          <div className='container-fluid  p-0'>
            <div className='row justify-content-center d-none d-md-block d-lg-block d-xl-block'>
              <div className='col-12'>
                <h2>Special occassion</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-4 p-0'>
                <img src={special1} className="w-100" alt="Special 1" />
              </div>
              <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-4 p-0'>
                <div className='specialInfo'>
                  <h5>Engagement Party</h5>
                  <p>
                    lorem ipsum lorem ipsum ignie lorem si pisetg
                  </p>
                  <button type='button' className='bookBtn d-none d-md-block d-lg-block d-xl-block' onClick={() => { setBookType(2); setShow(true) }}>
                    Book
                  </button>
                  <button type='button' className='bookBtn d-md-none d-lg-none d-xl-none d-xs-block d-sm-block' onClick={() => { setShowModel(true); setBookType(2) }}>
                    Book
                  </button>
                </div>
              </div>
              <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-4 p-0'>
                <img src={special2} className="w-100" alt="Special 2" />
              </div>
              <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-4 p-0'>
                <div className='specialInfo'>
                  <h5>Birthday Party</h5>
                  <p>
                    lorem ipsum lorem ipsum ignie lorem si pisetg
                  </p>
                  <button type='button' className='bookBtn d-none d-md-block d-lg-block d-xl-block' onClick={() => { setBookType(3); setShow(true) }}>
                    Book
                  </button>
                  <button type='button' className='bookBtn d-md-none d-lg-none d-xl-none d-xs-block d-sm-block' onClick={() => { setShowModel(true); setBookType(3) }}>
                    Book
                  </button>
                </div>
              </div>
              <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-4 p-0'>
                <img src={special3} className="w-100" alt="Special 3" />
              </div>
              <div className='col-6 col-md-3 col-lg-3 col-xl-2 mb-4 p-0'>
                <div className='specialInfo'>
                  <h5>Anniversary Party</h5>
                  <p>
                    lorem ipsum lorem ipsum ignie lorem si pisetg
                  </p>
                  <button type='button' className='bookBtn d-none d-md-block d-lg-block d-xl-block' onClick={() => { setBookType(4); setShow(true) }}>
                    Book
                  </button>
                  <button type='button' className='bookBtn d-md-none d-lg-none d-xl-none d-xs-block d-sm-block' onClick={() => { setShowModel(true); setBookType(4) }}>
                    Book
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={showModel} className="Business ">
          <Modal.Body>
            <div className='row'>
              <div className='col-12'>
                <button className='closeModel' type='button' onClick={() => setShowModel(false)}><FiX /></button>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-7 col-sm-6'>
                <label className='headingLabelModel'>Book an occassion</label>
              </div>
              <div className='col-5 col-sm-6 m-auto'>
                <div className='businessLine'></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-7 col-sm-6 subTitleMarginModel'>
                <label className='subTitleModel float-end'>
                  {bookType == 2 && "Engagement Party"}
                  {bookType == 3 && "Birthday Party"}
                  {bookType == 4 && "Anniversary Party"}
                </label>
              </div>
            </div>
            <div className='row mt-5 position-relative'>
              <div className='col-12'>
                <form className='BookingBox'>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='inputLabelModel'>Day:</label>
                          <br />
                          <input className='inputBoxModel' type='date' onChange={(e) => setDay(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='inputLabelModel'>Starting Time:</label>
                          <br />
                          <input className='inputBoxModel' type='datetime-local' placeholder='00/00' onChange={(e) => setStartingTime(e.target.value)} />
                          <button className='nextInputModel' type='button'>
                            <img src={amBtn} alt="AM" />
                          </button>
                          <button className='nextInputModel' type='button'>
                            <img src={pmBtn} alt="PM" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='inputLabelModel'>No. Of People:</label>
                          <br />
                          <label className='inputBoxModel text-center p-0'>{quantity}</label>
                          <button className='nextInputModel' type='button' onClick={() => ManageQuantity(1)}>
                            <img src={addSq} alt="add" />
                          </button>
                          <button className='nextInputModel' type='button' onClick={() => ManageQuantity(-1)}>
                            <img src={MinusSq} alt="remove" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='inputLabelModel'>Ending Time:</label>
                          <br />
                          <input className='inputBoxModel' type='datetime-local' placeholder='00/00' onChange={(e) => setEndingTime(e.target.value)} />
                          <button className='nextInputModel' type='button'>
                            <img src={amBtn} alt="AM" />
                          </button>
                          <button className='nextInputModel' type='button'>
                            <img src={pmBtn} alt="PM" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-12 text-center'>
                      <label className='inputLabelModel'>Instructions:</label>
                      <br />
                      <textarea rows='8' cols='5' className='textareaBoxSpecial' onChange={(e) => setInstructions(e.target.value)} />
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-12'>
                      <button type='button' className='specialBtnModel' onClick={bookAtable}>Book a table</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {show == true && (
          <div className='d-none d-md-block d-lg-block d-xl-block'>
            <div className='row HeadingPadding'>
              <div className='col-8 col-lg-6'>
                <label className='headingLabel'>Book an occassion</label>
              </div>
              <div className='col-4 col-lg-6 m-auto'>
                <div className='businessLine'></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-7 col-lg-5 subTitleMargin'>
                <label className='subTitle float-end'>
                  {bookType == 2 && "Engagement Party"}
                  {bookType == 3 && "Birthday Party"}
                  {bookType == 4 && "Anniversary Party"}
                </label>
              </div>
            </div>
            <div className='row mt-5 position-relative'>
              <div className='d-none d-lg-block col-lg-3 p-0'>
                <img src={BusinesstArt} alt="Business" />
              </div>
              <div className='col-12 col-lg-6'>
                <div className='BookingBox'>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='inputLabel'>Day:</label>
                          <br />
                          <input className='inputBox2' type='date' onChange={(e) => setDay(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='inputLabel'>Starting Time:</label>
                          <br />
                          <input className='inputBox2' type='datetime-local' placeholder='00/00' onChange={(e) => setStartingTime(e.target.value)} />
                          <button className='nextInput' type='button'>
                            <img src={amBtn} alt="AM" />
                          </button>
                          <button className='nextInput' type='button'>
                            <img src={pmBtn} alt="PM" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='inputLabel'>No. Of People:</label>
                          <br />
                          <label className='inputBox2 text-center'>{quantity}</label>
                          <button className='nextInput' type='button' onClick={() => ManageQuantity(1)}>
                            <img src={addSq} alt="add" />
                          </button>
                          <button className='nextInput' type='button' onClick={() => ManageQuantity(-1)}>
                            <img src={MinusSq} alt="remove" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='inputLabel'>Ending Time:</label>
                          <br />
                          <input className='inputBox2' type='datetime-local' placeholder='00/00' onChange={(e) => setEndingTime(e.target.value)} />
                          <button className='nextInput' type='button'>
                            <img src={amBtn} alt="AM" />
                          </button>
                          <button className='nextInput' type='button'>
                            <img src={pmBtn} alt="PM" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-12'>
                      <label className='inputLabel'>Instructions:</label>
                      <br />
                      <textarea rows='8' cols='5' className='textareaBoxSpecial' onChange={(e) => setInstructions(e.target.value)} />
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-6'>
                      <button type='reset' className='specialBtn' onClick={() => window.location.reload(false)}>Cancel</button>
                    </div>
                    <div className='col-6'>
                      <button type='button' className='specialBtn2' onClick={bookAtable}>Book a table</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-none d-lg-block col-lg-3 p-0'>
                <img src={BusinesstArt2} alt='Business' />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Special