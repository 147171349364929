import React, { useState } from 'react'
import amBtn from '../../Images/amBtn.png'
import pmBtn from '../../Images/pmBtn.png'
import addSq from '../../Images/plusSquare.png'
import MinusSq from '../../Images/minusSquare.png'
import bookingTable from '../../Images/tableBooking.png'
import { variables } from '../../Variables/Variables'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Booking = () => {
    const [step, setStep] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [tableNumber, setTableNumber] = useState(0)
    const [day, setDay] = useState('');
    const [startingTime, setStartingTime] = useState('');
    const [endingTime, setEndingTime] = useState('');
    const [instructions, setInstructions] = useState('');

    const ManageQuantity = (i) => {
        let q = quantity;
        if (i == -1) {
            if (q > 0) {
                q--
            }
        }
        else {
            q++
        }
        setQuantity(q)
    }

    function isValid() {
        if (
            quantity === 0 ||
            day === '' ||
            startingTime === '' ||
            endingTime === '' ||
            firstName === '' ||
            lastName === '' ||
            email === '' ||
            instructions === '' ||
            tableNumber === 0
        ) {
            toast.error("please fill required fields");
            return false;
        }
        return true
    }

    function bookAtable() {
        if (isValid()) {
            fetch(variables.API_URL + "Booking/BookATable", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    day: day,
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    email: email,
                    instructions: instructions,
                    tableNumber: tableNumber,
                    startingTime: startingTime,
                    endingTime: endingTime,
                    numberOfPeople: quantity,
                    bookingTypeId: 8
                }),
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return response.text().then((text) => {
                    throw new Error(text);
                });
            })
                .then((result) => {
                    toast.success(result );
                }).catch((error) => {
                    toast.error(error.message.replaceAll('"', ""));
                });
        }
    }
    return (
        <div className='container Booking aftermobileheader'>
            <div className='row text-center mt-5'>
                <div className='col-12'>
                    <label className='mainTitle'>Booking</label>
                </div>
            </div>
            {step == 0 && (
                <>
                    <div className='d-lg-none d-xl-none d-md-block d-sm-block d-xs-block'>
                        <div className="menuListScroll container-fluid">
                            <div className="row mt-5">
                                <div className="col-12 p-0">
                                    <ul className="menulist">
                                        <li className="active" onClick={() => setStep(0)}><a>Information</a></li>
                                        <li onClick={() => setStep(1)}><a>Date & Time</a></li>
                                        <li onClick={() => setStep(2)}><a>Table Position</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-lg-5 mb-lg-5'>
                        <div className='col-3 leftTabs d-none d-lg-block d-xl-block'>
                            <ul className='tabsList'>
                                <li><button type='button' className='tabBtnActive' onClick={() => setStep(0)}>Information</button></li>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(1)}>Date & Time</button></li>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(2)}>Table Position</button></li>
                            </ul>
                        </div>
                        <div className='col-12 col-lg-9 ps-lg-5 m-auto'>
                            <div className='row'>
                                <div className='col-6 pe-0'>
                                    <label className='inputLabel'>First Name:</label>
                                    <br />
                                    <input type='text' value={firstName} className='inputBox' placeholder='Name' onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className='col-6'>
                                    <label className='inputLabel'>Last Name:</label>
                                    <br />
                                    <input type='text' value={lastName} className='inputBox' placeholder='Name' onChange={(e) => setLastName(e.target.value)} />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='inputLabel'>Phone Number:</label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 pe-0'>
                                            <input type='text' value={phoneNumber} className='inputBox' placeholder='12 345 678' onChange={(e) => setPhoneNumber(e.target.value)} />
                                        </div>
                                        <div className='col-6 p-0'>
                                            <button type='button' className='verfiyBtn'>Verify</button>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='inputLabel'>E-Mail:</label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 pe-0'>
                                            <input type='email' value={email} className='inputBox' placeholder='example @ gmail .com' onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className='col-6 ps-0'>
                                            <button type='button' className='verfiyBtn'>Verify</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-6 pe-0'>
                                    <button type='button' className='backBtn'>Go back</button>
                                </div>
                                <div className='col-6'>
                                    <button type='button' className='continueBtn' onClick={() => setStep(1)}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {step == 1 && (
                <>
                    <div className='d-lg-none d-xl-none d-md-block d-sm-block d-xs-block'>
                        <div className="menuListScroll container-fluid">
                            <div className="row mt-5">
                                <div className="col-12 p-0">
                                    <ul className="menulist">
                                        <li onClick={() => setStep(0)}><a>Information</a></li>
                                        <li className="active" onClick={() => setStep(1)}><a>Date & Time</a></li>
                                        <li onClick={() => setStep(2)}><a>Table Position</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-lg-5 mb-lg-5'>
                        <div className='col-3 leftTabs d-none d-lg-block d-xl-block'>
                            <ul className='tabsList'>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(0)}>Information</button></li>
                                <li><button type='button' className='tabBtnActive' onClick={() => setStep(1)}>Date & Time</button></li>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(2)}>Table Position</button></li>
                            </ul>
                        </div>
                        <div className='col-12 col-lg-9 ps-lg-5 m-auto'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='inputLabel'>Day:</label>
                                    <br />
                                    <input className='inputBox' value={day} type='date' onChange={(e) => setDay(e.target.value)} />
                                </div>
                            </div>
                            <div className='row mt-lg-5'>
                                <div className='col-12 col-lg-6'>
                                    <label className='inputLabel'>Start Time:</label>
                                    <br />
                                    <input className='inputBox2' value={startingTime} type='datetime-local' placeholder='00/00' onChange={(e) => setStartingTime(e.target.value)} />
                                    <button className='nextInput' type='button'>
                                        <img src={amBtn} alt="AM" />
                                    </button>
                                    <button className='nextInput' type='button'>
                                        <img src={pmBtn} alt="PM" />
                                    </button>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <label className='inputLabel'>End Time:</label>
                                    <br />
                                    <input className='inputBox2' value={endingTime} type='datetime-local' placeholder='00/00' onChange={(e) => setEndingTime(e.target.value)} />
                                    <button className='nextInput' type='button'>
                                        <img src={amBtn} alt="AM" />
                                    </button>
                                    <button className='nextInput' type='button'>
                                        <img src={pmBtn} alt="PM" />
                                    </button>
                                </div>
                            </div>
                            <div className='row mt-lg-5'>
                                <div className='col-12 col-lg-6'>
                                    <label className='inputLabel'>No. Of People:</label>
                                    <br />
                                    <label className='inputBox2 text-center'>{quantity}</label>
                                    <button className='nextInput' type='button' onClick={() => ManageQuantity(1)}>
                                        <img src={addSq} alt="add" />
                                    </button>
                                    <button className='nextInput' type='button' onClick={() => ManageQuantity(-1)}>
                                        <img src={MinusSq} alt="remove" />
                                    </button>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-12'>
                                    <label className='inputLabel'>Instructions:</label>
                                    <br />
                                    <textarea rows='8' cols='5' className='textareaBoxSpecial' onChange={(e) => setInstructions(e.target.value)} />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-6 pe-0'>
                                    <button type='button' className='backBtn' onClick={() => setStep(0)}>Go back</button>
                                </div>
                                <div className='col-6'>
                                    <button type='button' className='continueBtn' onClick={() => setStep(2)}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {step == 2 && (
                <>
                    <div className='d-lg-none d-xl-none d-md-block d-sm-block d-xs-block'>
                        <div className="menuListScroll container-fluid">
                            <div className="row mt-5">
                                <div className="col-12 p-0">
                                    <ul className="menulist">
                                        <li onClick={() => setStep(0)}><a>Information</a></li>
                                        <li onClick={() => setStep(1)}><a>Date & Time</a></li>
                                        <li className="active" onClick={() => setStep(2)}><a>Table Position</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-lg-5 mb-lg-5'>
                        <div className='col-3 leftTabs d-none d-lg-block d-xl-block'>
                            <ul className='tabsList'>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(0)}>Information</button></li>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(1)}>Date & Time</button></li>
                                <li><button type='button' className='tabBtnActive' onClick={() => setStep(2)}>Table Position</button></li>
                            </ul>
                        </div>
                        <div className='cl-12 col-lg-9 ps-lg-5 m-auto'>
                            <div className='row'>
                                <div className='col-12'>
                                    <img src={bookingTable} alt='Table' />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-12 col-md-6 col-lg-6'>
                                    <label className='inputLabel'>Table Number:</label>
                                    <br />
                                    <input className='inputBox' value={tableNumber} type='number' onChange={(e) => setTableNumber(e.target.value)} />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-6 pe-0'>
                                    <button type='button' className='backBtn' onClick={() => setStep(1)}>Go back</button>
                                </div>
                                <div className='col-6'>
                                    <button type='button' className='continueBtn' onClick={bookAtable}>Book</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Booking