import { Link } from 'react-router-dom';
import footerLogo from '../../Images/footerLogo.png';
import {MdHomeFilled} from 'react-icons/md';
import {BsGridFill, BsFillPersonFill} from 'react-icons/bs';
import {HiShoppingCart} from 'react-icons/hi';
const Footer = () => {
    return (
        <>
        {/* large view */}
            <footer className='d-none d-lg-block d-xl-block largeFooter'>
                <div className="container topFooter">
                    <div className="row">
                        <div className="col text-center">
                            <img src={footerLogo} alt="footerLogo" />
                        </div>
                        <div className="col">
                            <h3>Service</h3>
                            <ul>
                                <li>
                                    <Link to="/">Membership</Link>
                                </li>
                                <li>
                                    <Link to="/">Products</Link>
                                </li>
                                <li>
                                    <Link to="/">Stories</Link>
                                </li>
                                <li>
                                    <Link to="/">Menu</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                        <h3>Company</h3>
                        <ul>
                                <li>
                                    <Link to="/">Membership</Link>
                                </li>
                                <li>
                                    <Link to="/">Products</Link>
                                </li>
                                <li>
                                    <Link to="/">Stories</Link>
                                </li>
                                <li>
                                    <Link to="/">Menu</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                        <h3>Our Menu</h3>
                        <ul>
                                <li>
                                    <Link to="/">Membership</Link>
                                </li>
                                <li>
                                    <Link to="/">Products</Link>
                                </li>
                                <li>
                                    <Link to="/">Stories</Link>
                                </li>
                                <li>
                                    <Link to="/">Menu</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                        <h3>Contact Us</h3>
                        <ul>
                                <li>
                                    <Link to="/">Membership</Link>
                                </li>
                                <li>
                                    <Link to="/">Products</Link>
                                </li>
                                <li>
                                    <Link to="/">Stories</Link>
                                </li>
                                <li>
                                    <Link to="/">Menu</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='container-fluid bottomFooter'>
                    <div className="row justify-content-center align-items-center bottomFooter">
                        <div className='col-lg-4'>
                            All Rights Reserved 2022
                        </div>
                    </div>
                </div>
            </footer>
        {/* mobile view */}
            <footer className='d-md-block d-lg-none d-xl-none d-xs-block d-sm-block mobileFooter'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-3 text-center'>
                            <Link to="/"><MdHomeFilled/></Link>
                        </div>
                        <div className='col-3 text-center'>
                            <Link to="/Menu"><BsGridFill/></Link>
                        </div>
                        <div className='col-3 text-center'>
                            <Link to="/Cart"><HiShoppingCart/></Link>
                        </div>
                        <div className='col-3 text-center'>
                           <Link to="/Profile"><BsFillPersonFill/></Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;