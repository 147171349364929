import React, { useEffect, useState } from "react";
import { variables } from "../../../Variables/Variables";

import addCart from '../../../Images/addCart.png'
import { BsHeart, BsHeartFill } from 'react-icons/bs'

import titleBg from '../../../Images/titleBgMenu.png'
import checkSquare from '../../../Images/checkSquare.png'

import { Link } from "react-router-dom";
import Util from "../../../Classes/Util";

const MenuList = (props) => {
    const [menu, setMenu] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchMenu = async () => {
        setIsLoading(true)
        const URL = variables.API_URL + "Menu/GetMenuByLanguageID/" + 1;
        await fetch(URL, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
            })
            .then((result) => {
                setMenu(result);

            });
        setIsLoading(false)
    };

    const addToCart = async (ID) => {
        if (sessionStorage.getItem("Guest") !== null) {
            var CartID = Util.decrypt(sessionStorage.getItem("GuestCart"));
            // console.log(CartID)
            await fetch(variables.API_URL + "Cart/AddItemToCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cartId: CartID,
                    quantity: 1,
                    productId: ID,
                    sizeId: 0,
                    addOns: [],
                    unrequiredIngredients: []
                })
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    getCartItems(CartID)
                })
        } else {
            var CartID = Util.decrypt(localStorage.getItem("Cart"));
            // console.log(CartID)
            await fetch(variables.API_URL + "Cart/AddItemToCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cartId: CartID,
                    quantity: 1,
                    productId: ID,
                    addOns: [],
                    sizeId: 0,
                    unrequiredIngredients: []
                })
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    getCartItems(CartID)
                })
        }
    }

    const getCartItems = async (ID) => {
        await fetch(variables.API_URL + "Cart/GetCartItems/1/" + ID, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                props.setCartCount(Result.Count)
                props.setCartItems(Result.CartItems)
            })
    }

    const addToWishlist = (ID) => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "WishList/AddItemToWishList", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    wishlistId: WishListId,
                    productId: ID,
                }),
            }).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount++;
                    props.setWishlistCount(wishListCount);
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "WishList/AddItemToWishList", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    wishlistId: WishListId,
                    productId: ID,
                }),
            }).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount++;
                    props.setWishlistCount(wishListCount);
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        }
    };

    const RemoveFromWishList = (ID) => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "Wishlist/RemoveItemFromWishlist/" + ID + "/" + WishListId, {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
            ).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount--;
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "Wishlist/RemoveItemFromWishlist/" + ID + "/" + WishListId, {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
            ).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount--;
                    props.setWishlistCount(wishListCount);
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        }
    };

    const CheckWishList = () => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + WishListId, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    if (Result.length > 0) {
                        props.setWishlistItems(Result)
                        props.setWishlistCount(Result.length);
                    }
                });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + WishListId, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    if (Result.length > 0) {
                        props.setWishlistItems(Result)
                        props.setWishlistCount(Result.length);
                    }
                });
        }
    };

    // const getWishlistItems = async (ID) => {
    //     await fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + ID, {
    //         method: "GET",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //         },

    //     })
    //         .then((Response) => {
    //             if (Response.ok) {
    //                 return Response.json();
    //             }
    //         })
    //         .then((Result) => {
    //             props.setWishlistCount(Result.length)
    //             props.setWishlistItems(Result)
    //         })
    // }



    useEffect(() => {
        fetchMenu();
    }, []);
    // console.log(props.cartItems)
    return (
        <>
            {isLoading ? (
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            ) : (
                <>
                    <div className="menuTitleBar aftermobileheader">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <img src={titleBg} alt="Bahia Menu Title" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="menuListScroll d-lg-none mt-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 p-0">
                                    <ul className="menulist">
                                        <li className="active"><label className="text-center">Menu</label></li>
                                        <li><Link to="/SpecialOccassion">Special Occassion</Link> </li>
                                        <li><Link to="/BusinessLunch">Business Lunch</Link> </li>
                                        <li><Link to="/EntertainmentPackages">Entertainment Packages</Link> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="menuSectionInner">
                        <div className="container">
                            <div className="row">
                                {menu.map((x, index) => {
                                    return (
                                        <div className="col-12" key={x.MenuId}>
                                            <h2>{x.MenuName}</h2>
                                            <div className="row">
                                                {x.Products.map((p, pindex) => {
                                                    // if (pindex % 2 === 0) {
                                                    return (
                                                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" key={p.ProductId}>
                                                            <div className='menuItem position-relative'>
                                                                <Link to={"/MenuDetailed/" + p.ProductId + '/' + p.ProductName.replace(" ", "-")}>
                                                                    <img src={variables.Photo_URL + p.ProductImage} alt={p.ProductName} className="menuPicture" />
                                                                </Link>
                                                                {props.wishlistItems.some((u) => u.ProductId == p.ProductId) == true ?
                                                                    <button className="wishlistBtn redBtn" type="button" onClick={() => RemoveFromWishList(p.ProductId)}><BsHeartFill /></button>
                                                                    : <button className="wishlistBtn" type="button" onClick={() => addToWishlist(p.ProductId)}><BsHeart /></button>
                                                                }
                                                                <Link to={"/MenuDetailed/" + p.ProductId + '/' + p.ProductName.replace(" ", "-")}>
                                                                    <h4>{p.ProductName}</h4>
                                                                    <p>
                                                                        {p.ProductIngredients.map((i) => {
                                                                            return (
                                                                                <span key={i.IngredientId}>
                                                                                    {i.IngredientName + ", "}
                                                                                </span>
                                                                            )
                                                                        })}
                                                                    </p>
                                                                </Link>
                                                                {props.cartItems.some((u) => u.ProductId == p.ProductId) ? (
                                                                        <img src={checkSquare} className="btnAddCart" />
                                                                    ) : (
                                                                        <img src={addCart} className="btnAddCart" onClick={() => addToCart(p.ProductId)} />
                                                                    )}
                                                            </div>
                                                            {/* <img
                                                                    className="d-block w-100 imgslider"
                                                                    src={variables.Photo_URL + p.ProductImage}
                                                                    alt="First slide"
                                                                    aria-hidden="false"
                                                                    onClick={() => ViewProduct(p.ProductId)}
                                                                />
                                                                <img
                                                                    className="d-block w-100 imgslider"
                                                                    src={blueItem}
                                                                    alt="First slide"
                                                                    aria-hidden="false"
                                                                />
                                                                <h4>{p.ProductName}</h4>
                                                                <p>
                                                                    {p.ProductIngredients.map((i) => {
                                                                        return (
                                                                            i.IngredientName + ","
                                                                        )
                                                                    })}
                                                                </p> */}
                                                        </div>
                                                    )
                                                }
                                                    // else {
                                                    //     return (
                                                    //         <div className="col-12 col-lg-3">
                                                    //             <img
                                                    //                 className="d-block w-100 imgslider"
                                                    //                 src={variables.Photo_URL + p.ProductImage}
                                                    //                 alt="First slide"
                                                    //                 aria-hidden="false"
                                                    //                 onClick={() => ViewProduct(p.ProductId)}
                                                    //             />
                                                    //             <img
                                                    //                 className="d-block w-100 imgslider"
                                                    //                 src={redItem}
                                                    //                 alt="First slide"
                                                    //                 aria-hidden="false"
                                                    //             />
                                                    //             <h4>{p.ProductName}</h4>
                                                    //             <p>
                                                    //                 {p.ProductIngredients.map((i) => {
                                                    //                     return (
                                                    //                         i.IngredientName + ","
                                                    //                     )
                                                    //                 })}
                                                    //             </p>
                                                    //         </div>
                                                    //     );
                                                    // }
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>

    );
};

export default MenuList;
