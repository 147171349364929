import { React, useState, useEffect } from 'react';
import { BsApple, BsGoogle } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Util from '../../Classes/Util';
import { variables } from '../../Variables/Variables';

const LogIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    function isValid() {
        if (
            email === "" ||
            password === ""
        ) {
            toast.error("please fill required fields");
            return false;
        } else {
            return true;
        }

    }

    const LogInUser = async () => {
        if (isValid()) {
            fetch(variables.API_URL + "Customer/CustomerLogin", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": variables.API_URL,
                },
                body: JSON.stringify({
                    Email: email,
                    Password: password,
                    registrationTypeId: 1,
                    firstName: "",
                    lastName: "",
                    profilePicture: "",
                }),
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                    return Response.text().then((text) => {
                        throw new Error(text);
                    });
                })
                .then((Result) => {
                    localStorage.setItem("User", Util.encrypt(email));
                    localStorage.setItem("Token", Util.encrypt(Result.CustomerToken));
                    localStorage.setItem("Number", Util.encrypt(Result.CustomerId));
                    // toast.success("Welcome Back");
                    // window.location.href="/Profile";
                    // sessionStorage.clear();
                    localStorage.setItem("WishList", Util.encrypt(Result.WishlistId));
                    localStorage.setItem("Cart", Util.encrypt(Result.CartId));
                    MoveWishList(Result.CustomerId);
                })
                .catch((error) => {
                    if (
                        error.message.replaceAll('"', "") === "Please Confirm Your Email"
                    ) {
                        navigate("/VerifyEmail")
                    } else if (error.message.replaceAll('"', "") === "Please Reset Your Password") {
                        navigate("/ResetPassword")
                    }
                    toast.error(error.message.replaceAll('"', ""));
                });
        }
    }

    const MoveCart = (Id) => {
        var Guest = Util.decrypt(sessionStorage.getItem("Guest"));
        fetch(variables.API_URL + "Cart/MergeItems/" + Guest + "/" + Id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": variables.API_URL,
            },
            body: JSON.stringify({
                Email: email,
                Password: password,
            }),
        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
                return Response.text().then((text) => {
                    throw new Error(text);
                });
            })
            .then((Result) => {
                toast.success("Welcome Back");
                navigate("/Profile");
                sessionStorage.clear();
            })
            .catch((error) => {
                if (error.message.replaceAll('"', "") === "Please Confirm Your Email") {
                    navigate("/LogIn")
                }
                toast.error(error.message.replaceAll('"', ""));
            });
    };

    const MoveWishList = (Id) => {
        var Guest = Util.decrypt(sessionStorage.getItem("Guest"));
        fetch(variables.API_URL + "Wishlist/MergeItems/" + Guest + "/" + Id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": variables.API_URL,
            },
            body: JSON.stringify({
                Email: email,
                Password: password,
            }),
        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
                return Response.text().then((text) => {
                    throw new Error(text);
                });
            })
            .then((Result) => {
                MoveCart(Id);
            })
            .catch((error) => {
                if (error.message.replaceAll('"', "") === "Please Confirm Your Email") {
                    navigate("/LogIn")
                }
                toast.error(error.message.replaceAll('"', ""));
            });
    };
    return (
        <>
            <div className='container-fluid Register d-none d-md-block d-lg-block '>
                <div className='row'>
                    <div className='col-6 LeftBox'>
                        <div className='row mt-3'>
                            <div className='col-12 text-center'>
                                <label className='title'>Sign In</label>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='col-12'>
                                <label className='inputLabel'>Email:</label>
                                <br />
                                <input type='text' className='inputBox' placeholder='example@mail.com' onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='inputLabel'>Password:</label>
                                <br />
                                <input type='Password' className='inputBox' placeholder='pass' onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <p className='forgetPass'>
                                    Forgot password?
                                    <a href='/ForgetPassword' className='fst-italic ms-2'>Click here</a>
                                </p>
                            </div>
                        </div>
                        <div className='row mt-5 mb-5'>
                            <div className='col-12 text-center'>
                                <label className='or'>OR:</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <button type="button" className='registerBtns'><BsGoogle /></button>
                            </div>
                            <div className='col-6'>
                                <button type="button" className='registerBtns'><FaFacebookF /></button>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='col-12'>
                                <button type='button' className='signupBtn' onClick={LogInUser}>Sign in</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 RightBox'>
                        <div className='row mt-3'>
                            <div className='col-12 text-center'>
                                <label className='title'>SIGN UP</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <p className='text'>
                                    <strong>Don't Have an account yet?</strong>
                                    <br />
                                    Then sign up here and benefit from our wide
                                    range of food today.
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <Link to="/Register" type='button' className='signupBtn'>Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid Register d-md-none d-lg-none aftermobileheader'>
                <div className='row mt-3'>
                    <div className='col-12 text-center'>
                        <label className='mobileTite'>Profile</label>
                    </div>
                </div>
                <div className='row bottomBorder'>
                    <div className='col-6 text-center'>
                        <label className='twoTabsActive'>Sign-in</label>
                    </div>
                    <div className='col-6 text-center'>
                        <label className='twoTabs' onClick={() => navigate("/Register")}>Sign-up</label>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-12'>
                        <label className='inputLabelMobile'>Email:</label>
                        <br />
                        <input type='text' className='inputBoxMobile' placeholder='example@mail.com' onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='inputLabelMobile'>Password:</label>
                        <br />
                        <input type='Password' className='inputBoxMobile' placeholder='password' onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <p className='forgetPassMobile'>
                            Forgot password?
                            <a href='/ForgetPassword' className='fst-italic ms-2'>Click here</a>
                        </p>
                    </div>
                </div>
                <div className='row mt-3 mb-3'>
                    <div className='col-12 text-center'>
                        <label className='orMobile'>OR:</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 mt-3'>
                        <button type="button" className='registerBtnsMobile'>Sign in with google <BsGoogle /></button>
                    </div>
                    <div className='col-12 mt-3'>
                        <button type="button" className='registerBtnsMobile'>Sign in with facebook<FaFacebookF /></button>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-12'>
                        <button type='button' className='signupBtnMobile' onClick={LogInUser}>Sign in</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogIn