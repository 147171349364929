import React, {useState} from 'react'
import {useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Util from '../../Classes/Util';
import { variables } from '../../Variables/Variables';

const VerfiyEmail = () => {
    const [vCode, setVCode] = useState('');
    const navigate = useNavigate();

    const verifyCode = async () =>{
        var ID = Util.decrypt(localStorage.getItem("Number"));
        fetch(variables.API_URL + "Customer/EmailConfirmation/" + ID, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": variables.API_URL,
          },
          body: JSON.stringify({
            code: vCode,
          }),
        })
          .then((Response) => {
            if (Response.ok) {
              return Response.json();
            }
            return Response.text().then((text) => {
              throw new Error(text);
            });
          })
          .then((Result) => {
            toast.success("Email SuccessFully Confirmed");
            navigate("/Profile")
          })
          .catch((error) => {
            if (
              error.message.replaceAll('"', "") === "Please Confirm Your Email"
            ) {
            }
            toast.error(error.message.replaceAll('"', ""));
          });
          console.log(vCode)
    }
  return (
    <div className='conainer-fluid Forget aftermobileheader'>
        <div className='row verifyBox'>
            <div className='col-12 mt-5'>
                <label className='inputLabel'>
                    Please enter your code sent by email.
                </label>
                <br/>
                <input type='text' placeholder='Code' className='inputBox mt-3' onChange={(e) => setVCode(e.target.value)}/>
                <br/>
                <button type='button' className='verfiyBtn mt-3' onClick={verifyCode}>Verify</button>
            </div>
        </div>
    </div>
  )
}

export default VerfiyEmail