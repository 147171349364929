import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import menuItem1 from '../../Images/menuItem1.png'
import menuItem2 from '../../Images/menuItem2.png'
import menuItem3 from '../../Images/menuItem3.png'
import menuItem4 from '../../Images/menuItem4.png'
import addCart from '../../Images/addCart.png'
import { variables } from '../../Variables/Variables'
import Util from '../../Classes/Util'
import { toast } from "react-toastify";

const Checkout = (props) => {
    const [cities, setCities] = useState([])
    const navigate = useNavigate()
    const [address, setAddress] = useState({
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        PostalCode: "",
        StreetName: "",
        BuildingName: "",
        CityId: 0,
        FloorNumber: 0,
    })
    const [furtherInstructions, setFurtherInstructions] = useState("")
    const [step, setStep] = useState(0);
    const [cartItems, setCartItems] = useState([{
        ProductId: 0,
        ProductName: "",
        Description: "",
        Image: "",
        Quantity: 0,
        Price: 0,
        AddOns: [],
        UnrequiredIngredients: []
    }])
    const [total, setTotal] = useState(0)
    const [cartArray, setCartArray] = useState([{
        productId: 0,
        quantity: 0,
        sizeId: 0,
        unrequiredIngredients: [
            {
                ingredientId: 0
            }
        ],
        orderAddOns: [
            {
                ingredientId: 0
            }
        ]
    }])

    const getCities = async () => {
        await fetch(variables.API_URL + "City/GetCitiesForDdl", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                setCities(Result)
            })
    }

    const checkout = async () => {
        var productWithQuantity = [];
        cartItems.map((item) => {
            return (
                productWithQuantity.push({
                    productId: item.ProductId,
                    quantity: item.Quantity,
                    sizeId: item.SizeId,
                    unrequiredIngredients: item.UnrequiredIngredients,
                    orderAddOns: item.AddOns.map((u) => { return { "IngredientId": u.IngredientId } })

                })
            )
        })

        if (localStorage.getItem("Number") !== null) {
            const ID = Util.decrypt(localStorage.getItem("Number"));
            await fetch(variables.API_URL + "Order/CreateCustomerOrder/" + ID, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    branchId: 1,
                    paymentMethodId: 1,
                    furtherInstructions: furtherInstructions,
                    productWithQuantity: productWithQuantity,
                    addressBook: address
                })

            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    toast.success(Result)
                    // navigate("/")
                }).catch((error) => {
                    toast.error(error.message.replaceAll('"', ""));
                });
        } else{
            const ID = Util.decrypt(sessionStorage.getItem("Guest"));
            await fetch(variables.API_URL + "Order/CreateGuestOrder/" + ID, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    branchId: 1,
                    paymentMethodId: 1,
                    furtherInstructions: furtherInstructions,
                    productWithQuantity: productWithQuantity,
                    addressBook: address
                })

            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    toast.success(Result)
                    // navigate("/")
                }).catch((error) => {
                    toast.error(error.message.replaceAll('"', ""));
                });
        }
        console.log(JSON.stringify({
            branchId: 1,
            paymentMethodId: 1,
            furtherInstructions: furtherInstructions,
            productWithQuantity: productWithQuantity,
            addressBook: address
        }))

    }

    const getCartItems = async () => {
        if (sessionStorage.getItem("Guest") !== null) {
            var CartID = Util.decrypt(sessionStorage.getItem("GuestCart"));
            await fetch(variables.API_URL + "Cart/GetCartItems/1/" + CartID, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    setCartItems(Result.CartItems)
                    setTotal(Result.Total)
                })
        }
        else {
            var CartID = Util.decrypt(localStorage.getItem("Cart"));
            await fetch(variables.API_URL + "Cart/GetCartItems/1/" + CartID, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    // console.log(Result)
                    setCartItems(Result.CartItems)
                    setTotal(Result.Total)
                })
        }
    }

    useEffect(() => {
        getCities();
        getCartItems();
        setTimeout(() => {
            window.carouselFunction();
        }, 2500);
    }, [])
    
    return (
        <div className='container-fluid Checkout mt-5 mb-5 aftermobileheader'>
            <div className='row text-center'>
                <div className='col-12'>
                    <label className='mainTitle'>check out</label>
                </div>
            </div>
            {step == 0 && (
                <>
                    <div className='d-lg-none d-xl-none d-md-block d-sm-block d-xs-block'>
                        <div className="menuListScroll container-fluid">
                            <div className="row mt-5">
                                <div className="col-12 p-0">
                                    <ul className="menulist">
                                        <li className="active" onClick={() => setStep(0)}><a>Location</a></li>
                                        <li onClick={() => setStep(1)}><a>Payment Information</a></li>
                                        <li onClick={() => setStep(2)}><a>Order Summary</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-lg-5'>
                        <div className='col-3 leftTabs d-none d-lg-block d-xl-block'>
                            <ul className='tabsList'>
                                <li><button type='button' className='tabBtnActive' onClick={() => setStep(0)}>Location</button></li>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(1)}>Payment Information</button></li>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(2)}>Order Summary</button></li>
                            </ul>
                        </div>
                        <div className='col-12 col-lg-9 m-auto'>
                            <div className='checkoutPadding'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <label className='inputLabel'>City:</label>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <select required className='inputBox' value={address.CityId} onChange={(e) => setAddress((prevState) => ({ ...prevState, CityId: parseInt(e.target.value,) }))}>
                                                    <option selected disabled >-- Please choose a country --</option>
                                                    {cities.map((u) => {
                                                        return (
                                                            <option key={u.CityId} value={u.CityId}>{u.CityName}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {/* <div className='col-6 pe-0'>
                                                <input type='text' className='inputBox' placeholder='City' />
                                            </div>
                                            <div className='col-6 p-0'>
                                                <button type='button' className='chooseBtn'>Choose here</button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='col-6 '>
                                        <label className='inputLabel'>Street Name:</label>
                                        <br />
                                        <input type='text' className='inputBox' placeholder='example street' value={address.StreetName} onChange={(e) => setAddress((prevState) => ({ ...prevState, StreetName: e.target.value, }))} />
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-6 pe-0'>
                                        <label className='inputLabel'>Building Name:</label>
                                        <br />
                                        <input type='text' className='inputBox' placeholder='example name' value={address.BuildingName} onChange={(e) => setAddress((prevState) => ({ ...prevState, BuildingName: e.target.value, }))} />
                                    </div>
                                    <div className='col-6'>
                                        <label className='inputLabel'>Floor Number:</label>
                                        <br />
                                        <input type='number' className='inputBox' placeholder='example number' value={address.FloorNumber} onChange={(e) => setAddress((prevState) => ({ ...prevState, FloorNumber: parseInt(e.target.value,) }))} />
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-12'>
                                        <label className='inputLabel'>Further Instructions:</label>
                                        <br />
                                        <textarea rows='5' cols='4' className='textareaBox' placeholder='Type here' value={furtherInstructions} onChange={(e) => setFurtherInstructions(e.target.value)} />
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-6'>
                                        <Link to="/Cart" type='button' className='backBtn'>Go back</Link>
                                    </div>
                                    <div className='col-6'>
                                        <button type='button' className='continueBtn' onClick={() => setStep(1)}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {step == 1 && (
                <>
                    <div className='d-lg-none d-xl-none d-md-block d-sm-block d-xs-block'>
                        <div className="menuListScroll container-fluid">
                            <div className="row mt-5">
                                <div className="col-12 p-0">
                                    <ul className="menulist">
                                        <li onClick={() => setStep(0)}><a>Location</a></li>
                                        <li className="active" onClick={() => setStep(1)}><a>Payment Information</a></li>
                                        <li onClick={() => setStep(2)}><a>Order Summary</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-lg-5'>
                        <div className='col-3 leftTabs d-none d-lg-block d-xl-block'>
                            <ul className='tabsList'>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(0)}>Location</button></li>
                                <li><button type='button' className='tabBtnActive' onClick={() => setStep(1)}>Payment Information</button></li>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(2)}>Order Summary</button></li>
                            </ul>
                        </div>
                        <div className='col-12 col-lg-9 m-auto'>
                            <div className='checkoutPadding'>
                                <div className='row text-center mb-5'>
                                    <div className='col-12'>
                                        <label className='cashText'>Cash on Delivery</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <label className='inputLabel'>First Name:</label>
                                        <br />
                                        <input type='text' className='inputBox' placeholder='Name' value={address.FirstName} onChange={(e) => setAddress((prevState) => ({ ...prevState, FirstName: e.target.value, }))} />
                                    </div>
                                    <div className='col-6'>
                                        <label className='inputLabel'>Last Name:</label>
                                        <br />
                                        <input type='text' className='inputBox' placeholder='Name' value={address.LastName} onChange={(e) => setAddress((prevState) => ({ ...prevState, LastName: e.target.value, }))} />
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-12'>
                                        <label className='inputLabel'>Phone Number:</label>
                                        <br />
                                        <input type='text' className='inputBox' placeholder='Phone' value={address.PhoneNumber} onChange={(e) => setAddress((prevState) => ({ ...prevState, PhoneNumber: e.target.value, }))} />
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-6'>
                                        <button type='button' className='backBtn' onClick={() => setStep(0)}>Go back</button>
                                    </div>
                                    <div className='col-6'>
                                        <button type='button' className='continueBtn' onClick={() => setStep(2)}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-12 col-lg-9 m-auto'>
                            <div className='checkoutPadding'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <label className='inputLabel'>Name On Card:</label>
                                        <br />
                                        <input type='text' className='inputBox' placeholder='Name' />
                                    </div>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <label className='inputLabel'>Type Of Card:</label>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6 pe-0'>
                                                <input type='text' className='inputBox' placeholder='Name' />
                                            </div>
                                            <div className='col-6 ps-0'>
                                                <button type='button' className='chooseBtn'>Choose here</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-6'>
                                        <label className='inputLabel'>Card Number:</label>
                                        <br />
                                        <input type='text' className='inputBox' placeholder='00000' />
                                    </div>
                                    <div className='col-6'>
                                        <label className='inputLabel'>Exp Date:</label>
                                        <br />
                                        <input type='date' className='inputBox' />
                                    </div>
                                </div>
                                <div className='row mt-4 text-center'>
                                    <div className='col-12'>
                                        <label className='orText'>OR</label>
                                        <br />
                                        <label className='cashText'>Cash on Delivery</label>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-6'>
                                        <button type='button' className='backBtn' onClick={() => setStep(0)}>Go back</button>
                                    </div>
                                    <div className='col-6'>
                                        <button type='button' className='continueBtn' onClick={() => setStep(2)}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </>
            )}
            {step == 2 && (
                <>
                    <div className='d-lg-none d-xl-none d-md-block d-sm-block d-xs-block'>
                        <div className="menuListScroll container-fluid">
                            <div className="row mt-5">
                                <div className="col-12 p-0">
                                    <ul className="menulist">
                                        <li onClick={() => setStep(0)}><a>Location</a></li>
                                        <li onClick={() => setStep(1)}><a>Payment Information</a></li>
                                        <li className="active" onClick={() => setStep(2)}><a>Order Summary</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-lg-5'>
                        <div className='col-3 leftTabs d-none d-lg-block d-xl-block'>
                            <ul className='tabsList'>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(0)}>Location</button></li>
                                <li><button type='button' className='tabBtn' onClick={() => setStep(1)}>Payment Information</button></li>
                                <li><button type='button' className='tabBtnActive' onClick={() => setStep(2)}>Order Summary</button></li>
                            </ul>
                        </div>
                        <div className='col-12 col-lg-9 m-auto'>
                            <div className='checkoutPadding'>
                                <div className='row menuSectionInner'>
                                    <div className='container'>
                                        <div className='row'>
                                            {cartItems.map((item) => {
                                                return (
                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4">
                                                        <div className='menuItem'>
                                                            <Link to={"/MenuDetailed/" + item.ProductId + "/" + item.ProductName}>
                                                                <img src={variables.Photo_URL + item.Image} alt={item.ProductName} className="menuPicture" />

                                                                <h4>{item.ProductName}</h4>
                                                                <p>
                                                                    {item.Description}
                                                                </p>
                                                            </Link>
                                                            <img src={addCart} className="btnAddCart" />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-6 pe-0'>
                                        <textarea className='textareaBox2' rows='3' cols='4' placeholder='City , street name, Building Name or number, Floor Number' />
                                    </div>
                                    <div className='col-6 mt-2 ps-0'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462562.6509516108!2d54.94755498679835!3d25.07575945545691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2slb!4v1649758505875!5m2!1sen!2slb" height="150px" style={{ border: 0, width: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='mapstyle'></iframe>
                                    </div>
                                </div>
                                <div className='row mt-5'>
                                    <div className='col-12 col-lg-6 '>
                                        <div className='totalInfo Cart'>
                                            {cartItems.map((item) => {
                                                return (
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-4 col-lg-4'>
                                                                <label className='itemName'>{item.ProductName}</label>
                                                            </div>
                                                            <div className='col-4 col-lg-5'>
                                                                <hr className='itemLine' />
                                                            </div>
                                                            <div className='col-4 col-lg-3'>
                                                                <label className='itemPrice'>{item.Price} A.E.D</label>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <ul className='finalCart'>
                                                                    <li><strong>Quantity:</strong> {item.Quantity}</li>
                                                                    <li><strong>Size:</strong> {item.SizeName}</li>
                                                                    {item.AddOns.length !== 0 && (
                                                                        <li><strong>AddOns:</strong>
                                                                            <ul className='addons'>
                                                                                {item.AddOns.map((adds) => {
                                                                                    return (
                                                                                        <li>
                                                                                            {adds.Name + " "} <span style={{ color: "red" }}>{adds.ExtraPrice + " A.E.D."}</span>
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </li>
                                                                    )}
                                                                    {item.UnrequiredIngredients.length !== 0 && (
                                                                        <li><strong>Removed:</strong>
                                                                            <ul className='addons'>
                                                                                {item.UnrequiredIngredients.map((adds) => {
                                                                                    return (
                                                                                        <li>
                                                                                            {adds.Name + " "}
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                            <hr />
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <label className='totalTitle'>TOTAL PRICE:</label>
                                                </div>
                                                <div className='col-6'>
                                                    <label className='totalAmount'>{total} A.E.D</label>
                                                </div>
                                            </div>
                                            {/* <div className='row mt-2'>
                                                <div className='col-6'>
                                                    <label className='totalVAT'>Total V.A.T</label>
                                                </div>
                                                <div className='col-6'>
                                                    <label className='VATamount'>18 A.E.D</label>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <label className='totalTitle'>TOTAL SUM:</label>
                                                </div>
                                                <div className='col-6'>
                                                    <label className='totalAmount'>72 A.E.D</label>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-6 buttonsPadding'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <button type='button' className='backBtn'>GO BACK</button>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-12'>
                                                <button type='button' className='continueBtn' onClick={checkout}>Done</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Checkout