import React, { useEffect } from 'react';
import awesomePhoneAlt from '../../../Images/awesome-phone-alt.png'
import metrolocation from '../../../Images/metro-location.png'
import featherMail from '../../../Images/feather-mail.png'
import careersArt from '../../../Images/careerArt.png'
import joinusArt from '../../../Images/joinusRight.png'

const Contact = () => {
    useEffect(() => {
        setTimeout(() => {
            window.carouselFunction();
        }, 1500);
    }, [])
    return (
        <>
            <div className='contactSection-Contact'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-4'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462562.6509516108!2d54.94755498679835!3d25.07575945545691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2slb!4v1649758505875!5m2!1sen!2slb" height="470" style={{ border: 0, width: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className='col-4'>
                            <div className='contactInfo'>
                                <h3>Meet Us</h3>
                                <p>
                                    <span>
                                        <img src={awesomePhoneAlt} alt="Phone" />
                                    </span>
                                    <span>
                                        +971 54 995 869
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <img src={featherMail} alt="Phone" />
                                    </span>
                                    <span>
                                        info@admin.com
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        <img src={metrolocation} alt="Phone" />
                                    </span>
                                    <span>
                                        1233 park street, town, Dubai
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className='contactForm'>
                                <h3>Contact Us</h3>
                                <input placeholder='Enter Email Here' />
                                <textarea placeholder='Enter Message Here' rows="4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='careersSection mb-sm-5'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-9 careersPadding'>
                            <div className='row'>
                                <div className='col-4'>
                                    <h2>CAREERS</h2>
                                </div>
                                <div className='col-8 m-auto'>
                                    <div className='careersLine'></div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 subTitleMargin'>
                                    <label className='subTitle'>open positions</label>
                                    <br />
                                    <p className='careersPars'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla.</p>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <h2>CAREERS</h2>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 subTitleMargin'>
                                    <label className='subTitle'>open positions</label>
                                    <br />
                                    <div className='owl-carousel owl-theme homePositionsCarousel mt-3'>
                                        <div class='item'>
                                            <div className='positionBox'>
                                                <div className='row m-auto'>
                                                    <div className='col-12'>
                                                        <label className='dept'>Department</label>
                                                        <label className='title'>Cook</label>
                                                        <br />
                                                        <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                    </div>
                                                    <button type='button' className='applyBtn'>Apply to job</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div className='positionBox'>
                                                <div className='row m-auto'>
                                                    <div className='col-12'>
                                                        <label className='dept'>Department</label>
                                                        <label className='title'>Cook</label>
                                                        <br />
                                                        <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                    </div>
                                                    <button type='button' className='applyBtn'>Apply to job</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div className='positionBox'>
                                                <div className='row m-auto'>
                                                    <div className='col-12'>
                                                        <label className='dept'>Department</label>
                                                        <label className='title'>Cook</label>
                                                        <br />
                                                        <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                    </div>
                                                    <button type='button' className='applyBtn'>Apply to job</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div className='positionBox'>
                                                <div className='row m-auto'>
                                                    <div className='col-12'>
                                                        <label className='dept'>Department</label>
                                                        <label className='title'>Cook</label>
                                                        <br />
                                                        <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                    </div>
                                                    <button type='button' className='applyBtn'>Apply to job</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div className='positionBox'>
                                                <div className='row m-auto'>
                                                    <div className='col-12'>
                                                        <label className='dept'>Department</label>
                                                        <label className='title'>Cook</label>
                                                        <br />
                                                        <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                    </div>
                                                    <button type='button' className='applyBtn'>Apply to job</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='item'>
                                            <div className='positionBox'>
                                                <div className='row m-auto'>
                                                    <div className='col-12'>
                                                        <label className='dept'>Department</label>
                                                        <label className='title'>Cook</label>
                                                        <br />
                                                        <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                    </div>
                                                    <button type='button' className='applyBtn'>Apply to job</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 d-none d-md-block d-lg-block d-xl-block'>
                            <img src={careersArt} className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='joinusSection'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-9 leftMargin m-auto'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='headingLabel'>Join us</label>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='inputLabel'>Position Applied To:</label>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 pe-0'>
                                            <input type='text' className='inputBox' placeholder='Job Position' />
                                        </div>
                                        <div className='col-6 p-0'>
                                            <button type='button' className='verfiyBtn'>Choose Here</button>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <label className='inputLabel'>Your E-Mail:</label>
                                    <br />
                                    <input type='text' className='inputBox' placeholder='example @ gmail .com' />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-12'>
                                    <label className='inputLabel'>Message:</label>
                                    <br/>
                                    <textarea rows='8' cols='5' className='textareaInput' placeholder='Type Here'/>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6'>
                                    <label for='attachment' className='attachLabel'>Attach File</label>
                                    <input id='attachment' type='file' className='d-none'/>
                                </div>
                                <div className='col-6'>
                                    <button type='button' className='verfiyBtn'>Send Email</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-3'>
                            <img src={joinusArt} alt="Join us" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact;