import React, { useEffect, useState } from 'react'
import { BsApple, BsGoogle } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import Util from '../../Classes/Util'
import { variables } from '../../Variables/Variables'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GoogleLogin from 'react-google-login';
// import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
const Register = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    function isValid() {
        if (
            email === "" ||
            password === "" ||
            firstName === "" ||
            lastName === "" ||
            username === "" ||
            phonenumber === "" ||
            confirmPassword === "" ||
            password === ""
        ) {
            toast.error("please fill required fields");
            return false;
        }
        else {
            var strongPasswordRegex = new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
            );
            var validpassword = strongPasswordRegex.test(password);
            if (!validpassword) {
                toast.error("Password must be minimum 8 characters consisting of numbers, uppercase and lowercase letters and Symbols")
                return false;
            }
            if (password !== confirmPassword) {
                toast.error("Passwords should be the same.")
            }
        }
        return true;
    }

    function RegisterUser() {
        if (isValid()) {
            const URL = variables.API_URL + "Customer/CustomerRegistration";
            fetch(URL, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": variables.API_URL,
                },
                body: JSON.stringify({
                    registrationTypeId: 1,
                    Email: email,
                    Password: password,
                    FirstName: firstName,
                    LastName: lastName,
                    username: username,
                    phoneNumber: phonenumber
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    return response.text().then((text) => {
                        throw new Error(text);
                    });
                })
                .then((result) => {
                    toast.success("Customer Successfully registered");
                    localStorage.setItem("Number", Util.encrypt(result.CustomerId));
                    navigate("/VerifyEmail")
                }).catch((error) => {
                    toast.error(error.message.replaceAll('"', ""));
                });
        }
    }
    const responseGoogle = (response) => {
        // console.log(response);
    }
    // const responseGoogle = (response) => {
    //     fetch(variables.API_URL + "Customer/CustomerLogin", {
    //         method: "POST",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             "Access-Control-Allow-Origin": variables.API_URL,
    //         },
    //         body: JSON.stringify({
    //             Email: response.profileObj.email,
    //             Password: "",
    //             registrationTypeId: 2,
    //             firstName: response.profileObj.givenName,
    //             lastName: response.profileObj.familyName,
    //             profilePicture: response.profileObj.imageUrl,
    //         }),
    //     })
    //         .then((Response) => {
    //             if (Response.ok) {
    //                 return Response.json();
    //             }
    //             return Response.text().then((text) => {
    //                 throw new Error(text);
    //             });
    //         })
    //         .then((Result) => {
    //             localStorage.setItem("User", Util.encrypt(email));
    //             localStorage.setItem("Token", Util.encrypt(Result.CustomerToken));
    //             localStorage.setItem("Number", Util.encrypt(Result.CustomerId));
    //             // toast.success("Welcome Back");
    //             // window.location.href="/Profile";
    //             // sessionStorage.clear();
    //             localStorage.setItem("WishList", Util.encrypt(Result.WishlistId));
    //             localStorage.setItem("Cart", Util.encrypt(Result.CartId));
    //             MoveWishList(Result.CustomerId);
    //         })
    //         .catch((error) => {
    //             localStorage.setItem("error", error)
    //             toast.error(error.message.replaceAll('"', ""));
    //         });
    // };

    //   const responseFacebook = (response) => {
    //     var firstName = "",
    //       lastName = "";
    //     var name = response.name.split(" ");

    //     fetch(Global.API_URL + "Customer/CustomerLogin", {
    //       method: "POST",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Origin": Global.API_URL,
    //       },
    //       body: JSON.stringify({
    //         Email: response.email,
    //         Password: "",
    //         registrationTypeId: 2,
    //         firstName: name[0],
    //         lastName: name[1],
    //         profilePicture: response.picture.data.url,
    //       }),
    //     })
    //       .then((Response) => {
    //         if (Response.ok) {
    //           return Response.json();
    //         }
    //         return Response.text().then((text) => {
    //           throw new Error(text);
    //         });
    //       })
    //       .then((Result) => {
    //         localStorage.setItem("User", Util.encrypt(response.email));
    //         localStorage.setItem("SISToken", Util.encrypt(Result.CustomerToken));
    //         localStorage.setItem("Number", Util.encrypt(Result.CustomerId));

    //         localStorage.setItem("WishList", Util.encrypt(Result.WishlistId));
    //         localStorage.setItem("Cart", Util.encrypt(Result.CartId));
    //         localStorage.setItem("Subscribe", Util.encrypt(Result.IsSubscribed));
    //         MoveWishList(Result.CustomerId);
    //         props.setSubscribe(Result.IsSubscribed);
    //       })
    //       .catch((error) => {
    //         toast.error(error.message.replaceAll('"', ""));
    //       });
    //   };

    const MoveCart = (Id) => {
        var Guest = Util.decrypt(sessionStorage.getItem("Guest"));
        fetch(variables.API_URL + "Cart/MergeItems/" + Guest + "/" + Id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": variables.API_URL,
            },
            body: JSON.stringify({
                Email: email,
                Password: password,
            }),
        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
                return Response.text().then((text) => {
                    throw new Error(text);
                });
            })
            .then((Result) => {
                toast.success("Welcome Back");
                navigate("/Profile");
                sessionStorage.clear();
            })
            .catch((error) => {
                if (error.message.replaceAll('"', "") === "Please Confirm Your Email") {
                    navigate("/LogIn")
                }
                toast.error(error.message.replaceAll('"', ""));
            });
    };

    const MoveWishList = (Id) => {
        var Guest = Util.decrypt(sessionStorage.getItem("Guest"));
        fetch(variables.API_URL + "Wishlist/MergeItems/" + Guest + "/" + Id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": variables.API_URL,
            },
            body: JSON.stringify({
                Email: email,
                Password: password,
            }),
        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
                return Response.text().then((text) => {
                    throw new Error(text);
                });
            })
            .then((Result) => {
                MoveCart(Id);
            })
            .catch((error) => {
                if (error.message.replaceAll('"', "") === "Please Confirm Your Email") {
                    navigate("/LogIn")
                }
                toast.error(error.message.replaceAll('"', ""));
            });
    };

    function handleResponse(response) {
        console.log(response.credential)
    }

    useEffect(() => {

    }, [])


    return (
        <>
            <div className='container-fluid Register d-none d-md-block d-lg-block '>
                <div className='row'>
                    <div className='col-6 LeftBox'>
                        <div className='row mt-3'>
                            <div className='col-12 text-center'>
                                <label className='title'>SIGN IN</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <p className='text2'>
                                    <strong>Don't Have an account yet?</strong>
                                    <br />
                                    Then sign up here and benefit from our wide
                                    range of food today.
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <Link to="/Login" type='button' className='signupBtn'>Sign In</Link>
                            </div>
                        </div>

                    </div>
                    <div className='col-6 RightBox'>
                        <div className='row mt-3'>
                            <div className='col-12 text-center'>
                                <label className='title'>Sign UP</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <label className='inputLabel2'>First Name:</label>
                                <br />
                                <input type='text' className='inputBox' placeholder='Name' onChange={(e) => { setFirstName(e.target.value); }} />
                            </div>
                            <div className='col-6'>
                                <label className='inputLabel2'>Last Name:</label>
                                <br />
                                <input type='text' className='inputBox' placeholder='Name' onChange={(e) => { setLastName(e.target.value); }} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='inputLabel2'>E-Mail:</label>
                                <br />
                                <input type='email' className='inputBox' placeholder='example@gmail.com' onChange={(e) => { setEmail(e.target.value); }} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='inputLabel2'>Phone Number:</label>
                                <br />
                                <input type='text' className='inputBox' placeholder='50 568 799' onChange={(e) => { setPhonenumber(e.target.value); }} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='inputLabel2'>User Name:</label>
                                <br />
                                <input type='text' className='inputBox' placeholder='50 568 799' onChange={(e) => { setUsername(e.target.value); }} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='inputLabel2'>Password:</label>
                                <br />
                                <input type='Password' className='inputBox' placeholder='pass' onChange={(e) => { setPassword(e.target.value); }} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <label className='inputLabel2'>Confirm Password:</label>
                                <br />
                                <input type='Password' className='inputBox' placeholder='pass' onChange={(e) => { setConfirmPassword(e.target.value); }} />
                            </div>
                        </div>
                        <div className='row mt-3 mb-3'>
                            <div className='col-12 text-center'>
                                <label className='or2'>OR:</label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                {/* <GoogleLogin
                                    clientId="438961405033-e29qeog9rfq08dp6jjeb743rq25eod3v.apps.googleusercontent.com"
                                    buttonText={<BsGoogle />}
                                    className="registerBtns2 text-center"
                                    onSuccess={responseGoogle}
                                    cookiePolicy={"single_host_origin"}
                                    icon={false}
                                /> */}
                                {/* <GoogleLogin
                                    clientId="438961405033-e29qeog9rfq08dp6jjeb743rq25eod3v.apps.googleusercontent.com"
                                    buttonText="Login"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />, */}
                                {/* <div id="g_id_onload"
                                    data-client_id="438961405033-e29qeog9rfq08dp6jjeb743rq25eod3v.apps.googleusercontent.com"
                                    data-context="signin"
                                    data-ux_mode="popup"
                                    data-callback={responseGoogle}
                                    data-auto_prompt="false">
                                </div>

                                <div className="g_id_signin"
                                    data-type="standard"
                                    data-shape="rectangular"
                                    data-theme="outline"
                                    data-text="signin_with"
                                    data-size="large"
                                    data-logo_alignment="left">
                                </div> */}
                                {/* <GoogleLogin
                                    clientId="438961405033-e29qeog9rfq08dp6jjeb743rq25eod3v.apps.googleusercontent.com"
                                    buttonText="Login"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={"single_host_origin"}
                                /> */}
                                <div id='loginGoogle'></div>
                                {/* <button type="button" className='registerBtns2'><BsApple /></button> */}
                            </div>
                            <div className='col-6'>
                                <button type="button" className='registerBtns2'><FaFacebookF /></button>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='col-12'>
                                <button type='button' className='signupBtn' onClick={RegisterUser}>Sign up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid Register d-md-none d-lg-none aftermobileheader'>
                <div className='row mt-3'>
                    <div className='col-12 text-center'>
                        <label className='mobileTite'>Profile</label>
                    </div>
                </div>
                <div className='row bottomBorder'>
                    <div className='col-6 text-center'>
                        <label className='twoTabs'>Sign-in</label>
                    </div>
                    <div className='col-6 text-center'>
                        <label className='twoTabsActive'>Sign-up</label>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-6'>
                        <label className='inputLabelMobile'>First Name:</label>
                        <br />
                        <input type='text' className='inputBoxMobile' placeholder='Name' onChange={(e) => { setFirstName(e.target.value); }} />
                    </div>
                    <div className='col-6'>
                        <label className='inputLabelMobile'>Last Name:</label>
                        <br />
                        <input type='text' className='inputBoxMobile' placeholder='Name' onChange={(e) => { setLastName(e.target.value); }} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='inputLabelMobile'>Email:</label>
                        <br />
                        <input type='email' className='inputBoxMobile' placeholder='50 568 799' onChange={(e) => { setEmail(e.target.value); }} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='inputLabelMobile'>Phone Number:</label>
                        <br />
                        <input type='text' className='inputBoxMobile' placeholder='50 568 799' onChange={(e) => { setPhonenumber(e.target.value); }} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='inputLabelMobile'>User Name:</label>
                        <br />
                        <input type='text' className='inputBoxMobile' placeholder='Name' onChange={(e) => { setUsername(e.target.value); }} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='inputLabelMobile'>Password:</label>
                        <br />
                        <input type='Password' className='inputBoxMobile' placeholder='password' onChange={(e) => { setPassword(e.target.value); }} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='inputLabelMobile'>Confirm Password:</label>
                        <br />
                        <input type='Password' className='inputBoxMobile' placeholder='password' onChange={(e) => { setConfirmPassword(e.target.value); }} />
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <p className='forgetPassMobile'>
                            Forgot password?
                            <a href='/ForgetPassword' className='fst-italic ms-2'>Click here</a>
                        </p>
                    </div>
                </div>
                <div className='row mt-3 mb-3'>
                    <div className='col-12 text-center'>
                        <label className='orMobile'>OR:</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 mt-3'>
                        <button type="button" className='registerBtnsMobile'>Sign in with google <BsGoogle /></button>
                    </div>
                    <div className='col-12 mt-3'>
                        <button type="button" className='registerBtnsMobile'>Sign in with facebook<FaFacebookF /></button>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-12'>
                        <button type='button' className='signupBtnMobile' onClick={RegisterUser}>Sign in</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register