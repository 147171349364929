import React from 'react'
import leftImg from '../../../Images/error1.png'
import rightImg from '../../../Images/error2.png'
import logo from '../../../Images/errorLogo.png'

const ErrorPage = () => {
  return (
    <div className='container-fluid p-0 Error aftermobileheader'>
        <div className='row mt-3 mb-3'>
            <div className='col-3 p-0'>
                <img src={leftImg} alt="error"/>
            </div>
            <div className='col-6 middleCenter'>
                <div className='row'>
                    <div className='col-12'>
                        <img src={logo} alt="Bahia-Khanom" className='logo'/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <label className='mainTitle'>Error 404</label>
                    </div>
                </div>
                <hr/>   
                <div className='row'>
                    <div className='col-12'>
                        <label className='text'>Page Not Found</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <button type='button' className='backBtn'>Go Back Home</button>
                    </div>
                </div>
            </div>
            <div className='col-3 p-0'>
                <img src={rightImg} alt="error"/>
            </div>
        </div>
    </div>
  )
}

export default ErrorPage