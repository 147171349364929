import React, { useState } from 'react'
import { variables } from '../../Variables/Variables';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const ForgetPassword = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const forgetPass = async () => {
        await fetch(variables.API_URL + "Customer/CustomerForgetPassword/", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Email: email,
            })

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                toast.success("Please Check your email.")
                navigate("/Login")
            }).catch((error) => {
                toast.error(error.message.replaceAll('"', ""));
            });
    }
    return (
        <>
            <div className='container-fluid Forget aftermobileheader'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <label className='mainTitle'>forgot password</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <label className='inputLabel'>E-mail</label>
                        <br />
                        <input type='email' className='inputBox' placeholder='Name' onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                {/* <div className='row mt-3'>
                <div className='col-12'>
                    <label className='inputLabel'>Username:</label>
                    <br/>
                    <input type='text' className='inputBox' placeholder='Name'/>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <label className='inputLabel'>Old Password:</label>
                    <br/>
                    <input type='password' className='inputBox' placeholder='Pass'/>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <label className='inputLabel'>New Password:</label>
                    <br/>
                    <input type='password' className='inputBox' placeholder='Pass'/>
                </div>
            </div>*/}
                <div className='row mt-5'>
                    <div className='col-12'>
                        <button type='button' className='confirmBtn' onClick={forgetPass}>Send Email</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgetPassword