import React, {useEffect} from 'react';
import storyImg from '../../../Images/story1.png';
import gallery1 from '../../../Images/2.png';
import gallery2 from '../../../Images/4.png';
import gallery3 from '../../../Images/5.png';
import gallery4 from '../../../Images/7.png';

const Story = () => {
    

    // const getStory = async () => {
    //     await fetch(variables.API_URL + "Story/GetStoryForFrontEnd/1/1", {
    //         method: "GET",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //         },

    //     })
    //         .then((Response) => {
    //             if (Response.ok) {
    //                 return Response.json();
    //             }
    //         })
    //         .then((Result) => {
    //             // setStoryText(Result)
    //         })
    // }

    useEffect(() => {
        setTimeout(() => {
            window.carouselFunction();
        }, 1500);
    }, [])

    return (
        <>
            <div className="storySectionInner">
                <div className="container">
                    <div className='row'>
                        <div className="col-12 col-lg-5">
                            <img src={storyImg} alt="Story Image" />
                        </div>
                        <div className="col-12 col-lg-7 storyContent">
                            <h2>Story</h2>
                            <p>
                                Bahia Khanum reflects the story of a country destined to survive and succeed, the story of family dining among the sweet memories of a Lebanese village, in a country woven by authenticity and traditions, decorated with generosity, joy and love of life. A young aristocratic girl, grew up, surrounded by love and care, in a house guarded by pride and esteem. Family traditions forbidding the ladies of the manor to visit the kitchen could not consist a barrier for her ambition and joy of cooking and introducing distinctive ingredients, techniques and dishes. The chef who may not prevent her from participating, couldn't help but notice her
                                cuisine ability and great taste for good healthy food. Her delicious creations prepared behind the kitchen doors brought admiration, by the unsuspecting parents, to the chef work.
                            </p>
                            <p>
                                However, when the cat was let out of the bag, Mademoiselle Bahia s (Bahia Khanum) parents commended her culinary skills and encouraged her appetizing talent. She decided to further her hobby by making it a career, sharing her meals with the villagers, and spreading the picturesque charm of Lebanese village though its nutrient rich resources and food. Bahia Khanum Restaurant is a traditional Lebanese house with a modern healthy flair, representing the soul and culture of the village, that we all miss, a place of rural nature in an urbanized environment with an unmatched warm welcome, that makes us contemplate nostalgically the Lebanon of the past, and at the same time celebrate its richness and fill our senses with delight.
                            </p>
                            <p>
                                The Restaurants pie safes ( namlieh ), the goodies cupboards, with plenty of joy and colorful provisions, such as olive oil, makdous , kishk , jams, thyme, pine nuts, pomegranate molasses, tomato paste, and much more, bear witness to the generosity and fertility of the land, in addition to the stories behind their making. Bahia Khanum Restaurant is not just a delicious healthy meal, it is a story of distinction and splendor, allowing you to bring back precious memories, and take some of them with you home. It s a retail store offering a wide variety of organic products that will enhance your cuisine and ameliorate your health.
                            </p>
                            <p>
                                At Bahia…Happy Familla (familla pronunciation famiia) Village to fork, Bahia’s work Village to table, Bahia’s able Bahia Khanum cuisine, a dream of a queen
                                Bahia Khanum cuisine, tasty and green Bahia’s Table, Hunger Disable
                            </p>
                        </div>
                    </div>

                </div>
                <div className='container-fluid storyGallery'>
                    <div className='row'>
                        <div className='col-12'>
                            <h3>Gallery</h3>
                            <div className='owl-carousel owl-theme storyGalleryCarousel'>
                                <div class='item'>
                                    <img src={gallery1} alt="Gallery 1" />
                                </div>
                                <div class='item'>
                                    <img src={gallery2} alt="Gallery 2" />
                                </div>
                                <div class='item'>
                                    <img src={gallery3} alt="Gallery 3" />
                                </div>
                                <div class='item'>
                                    <img src={gallery4} alt="Gallery 4" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Story;