import rightLongArrow from '../../../Images/rightLongArrow.png'
import addCart from '../../../Images/addCart.png'
import story from '../../../Images/story.png'

import awesomePhoneAlt from '../../../Images/awesome-phone-alt.png'
import metrolocation from '../../../Images/metro-location.png'
import featherMail from '../../../Images/feather-mail.png'

import careersArt from '../../../Images/careerArt.png'
import checkSquare from '../../../Images/checkSquare.png'

import { Link } from 'react-router-dom'
import React, { useEffect, useState } from "react";
import { variables } from '../../../Variables/Variables'
import Util from '../../../Classes/Util'
import { BsHeart, BsHeartFill } from 'react-icons/bs'

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
import "swiper/css";
import 'swiper/css/navigation'
import 'swiper/css/pagination'



const Home = (props) => {
    const [menu, setMenu] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [homeSlider, setHomeSlider] = useState([]);
    const [businesSlider, setBusinessSlider] = useState([]);
    const [bookingTypes, setBookingTypes] = useState([]);
    const [storyText, setStoryText] = useState([])
    SwiperCore.use([Autoplay, Pagination, Navigation]);

    const fetchMenu = async () => {
        const URL = variables.API_URL + "Menu/GetMenuByLanguageID/" + 1;
        await fetch(URL, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
            })
            .then((result) => {
                setMenu(result);
                setIsLoading(false)
            });
    };

    const addToCart = async (ID) => {
        if (sessionStorage.getItem("Guest") !== null) {
            var CartID = Util.decrypt(sessionStorage.getItem("GuestCart"));
            await fetch(variables.API_URL + "Cart/AddItemToCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cartId: CartID,
                    quantity: 1,
                    productId: ID,
                    sizeId: 0,
                    addOns: [],
                    unrequiredIngredients: []
                })
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    getCartItems(CartID)
                })
        } else {
            var CartID = Util.decrypt(localStorage.getItem("Cart"));
            await fetch(variables.API_URL + "Cart/AddItemToCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cartId: CartID,
                    quantity: 0,
                    productId: ID,
                    addOns: [],
                    unrequiredIngredients: []
                })
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    getCartItems(CartID)
                })
        }
    }

    const getCartItems = async (ID) => {
        await fetch(variables.API_URL + "Cart/GetCartItems/1/" + ID, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                props.setCartCount(Result.Count)
                props.setCartItems(Result.CartItems)
            })
    }

    const getHomeSlider = async () => {
        setIsLoading(true)
        await fetch(variables.API_URL + "Slider/GetSlidesBySliderId/1", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                setHomeSlider(Result)
            })
    }

    const getBusiness = async () => {
        await fetch(variables.API_URL + "Slider/GetSlidesBySliderId/1", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                setBusinessSlider(Result)
            })
    }
    const getStory = async () => {
        await fetch(variables.API_URL + "Story/GetStoryForFrontEnd/1/1", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                setStoryText(Result)
            })
    }

    const getBookingTypes = async () => {
        await fetch(variables.API_URL + "BookingType/GetBookingTypes/1", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },

        })
            .then((Response) => {
                if (Response.ok) {
                    return Response.json();
                }
            })
            .then((Result) => {
                setBookingTypes(Result)
            })
    }

    const addToWishlist = (ID) => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "WishList/AddItemToWishList", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    wishlistId: WishListId,
                    productId: ID,
                }),
            }).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount++;
                    props.setWishlistCount(wishListCount);
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "WishList/AddItemToWishList", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    wishlistId: WishListId,
                    productId: ID,
                }),
            }).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount++;
                    props.setWishlistCount(wishListCount);
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        }
    };

    const RemoveFromWishList = (ID) => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "Wishlist/RemoveItemFromWishlist/" + ID + "/" + WishListId, {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
            ).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount--;
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "Wishlist/RemoveItemFromWishlist/" + ID + "/" + WishListId, {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
            ).then((Response) => {
                if (Response.ok) {
                    var wishListCount = props.wishListCount;
                    wishListCount--;
                    props.setWishlistCount(wishListCount);
                    setTimeout(() => {
                        CheckWishList();
                    }, 500);
                }
            });
        }
    };

    const CheckWishList = () => {
        if (localStorage.getItem("WishList") !== null) {
            var WishListId = Util.decrypt(localStorage.getItem("WishList"));
            fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + WishListId, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    if (Result.length > 0) {
                        props.setWishlistItems(Result)
                        props.setWishlistCount(Result.length);
                    }
                });
        } else {
            var WishListId = Util.decrypt(sessionStorage.getItem("GuestWishlist"));
            fetch(variables.API_URL + "Wishlist/GetWishlistItems/1/" + WishListId, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((Response) => {
                    if (Response.ok) {
                        return Response.json();
                    }
                })
                .then((Result) => {
                    if (Result.length > 0) {
                        props.setWishlistItems(Result)
                        props.setWishlistCount(Result.length);
                    }
                });
        }
    };


    useEffect(() => {
        getHomeSlider();
        getBusiness();
        getStory();
        getBookingTypes();
        fetchMenu();
        setTimeout(() => {
            window.carouselFunction();
        }, 1500);
    }, [])


    return (
        <>
            {isLoading ? (
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            ) : (

                <div className='beforerFooter aftermobileheader'>
                    {homeSlider.map((slide, index) => {
                        return (
                            <Swiper slidesPerView={1} autoplay={{
                                "delay": 2500,
                                "disableOnInteraction": false
                            }}>
                                <SwiperSlide key={index}>
                                    {slide.slides.map((s, index2) => {
                                        return (
                                            <img src={variables.Photo_URL + s.SlideImage} key={index2} alt={s.SlideName} />
                                        )
                                    })}
                                </SwiperSlide>
                            </Swiper>
                        )
                    })}
                    <div className='menuSection'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-6'>
                                    <h2>Menu</h2>
                                </div>
                                <div className='col-6 text-right'>
                                    <Link to="/Menu">
                                        <span>
                                            View Full Menu
                                        </span>
                                        <span>
                                            <img src={rightLongArrow} alt="Arrow" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 menuSectionInner'>
                                    <Swiper slidesPerView={1}
                                        autoplay={{
                                            "delay": 4500,
                                            "disableOnInteraction": false
                                        }}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1
                                            },
                                            200: {
                                                slidesPerView: 1
                                            },
                                            300: {
                                                slidesPerView: 1
                                            },
                                            400: {
                                                slidesPerView: 1,
                                            },
                                            600: {
                                                slidesPerView: 2,
                                            },
                                            700: {
                                                slidesPerView: 2,
                                            },
                                            1000: {
                                                slidesPerView: 3,
                                            },
                                            1200: {
                                                slidesPerView: 4,
                                            }
                                        }}

                                    >
                                        {menu.map((x) => {
                                            return (
                                                <>
                                                    {x.Products.map((p) => {
                                                        return (
                                                            <SwiperSlide key={p.ProductId}>
                                                                <div className='menuItem position-relative'>
                                                                    <Link to={"/MenuDetailed/" + p.ProductId + '/' + p.ProductName.replace(" ", "-")}>
                                                                        <img src={variables.Photo_URL + p.ProductImage} alt={p.ProductName} className="menuPicture" />
                                                                    </Link>
                                                                    {props.wishlistItems.some((u) => u.ProductId == p.ProductId) == true ?
                                                                        <button className="wishlistBtn redBtn" type="button" onClick={() => RemoveFromWishList(p.ProductId)}><BsHeartFill /></button>
                                                                        : <button className="wishlistBtn" type="button" onClick={() => addToWishlist(p.ProductId)}><BsHeart /></button>
                                                                    }
                                                                    <Link to={"/MenuDetailed/" + p.ProductId + '/' + p.ProductName.replace(" ", "-")}>
                                                                        <h4>{p.ProductName}</h4>
                                                                        <p>
                                                                            {p.ProductIngredients.map((i) => {
                                                                                return (
                                                                                    <span key={i.IngredientId}>
                                                                                        {i.IngredientName + ", "}
                                                                                    </span>
                                                                                )
                                                                            })}
                                                                        </p>
                                                                    </Link>
                                                                    {props.cartItems.some((u) => u.ProductId == p.ProductId) ? (
                                                                        <img src={checkSquare} className="btnAddCart" />
                                                                    ) : (
                                                                        <img src={addCart} className="btnAddCart" onClick={() => addToCart(p.ProductId)} />
                                                                    )}
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                    })}
                                                </>
                                            )
                                        }
                                        )}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='storySection d-none d-md-block d-lg-block d-xl-block'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12 col-lg-6'>
                                    <img src={variables.Photo_URL + storyText.Image} alt="Story" />
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <div className='storyContent'>
                                        <h2>Story</h2>
                                        <div className='storyBar'></div>
                                        <p>
                                           {storyText.Text}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lunchSection'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h3>
                                        business lunch
                                    </h3>
                                </div>
                                <div className='col-12 businessSlider p-0'>
                                    {businesSlider.map((slide, index) => {
                                        return (
                                            <Swiper slidesPerView={1} autoplay={{
                                                "delay": 2500,
                                                "disableOnInteraction": false
                                            }}>
                                                <SwiperSlide key={index}>
                                                    {slide.slides.map((s, index2) => {
                                                        return (
                                                            <>
                                                                <img src={variables.Photo_URL + s.SlideImage} key={index2} alt={s.SlideName} />
                                                                <Link to="/BusinessLunch">Book a Table</Link>
                                                            </>
                                                        )
                                                    })}
                                                </SwiperSlide>
                                            </Swiper>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='specialSection'>
                        <div className='container-fluid d-none d-md-block d-lg-block d-xl-block'>
                            {bookingTypes.filter((x) => x.BookingTypeId == 10).map((item) => {
                                return (
                                    <>
                                        <div className='row justify-content-center' key={item.BookingTypeId}>
                                            <div className='col-12'>
                                                <h2>{item.Type}</h2>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Swiper autoplay={{
                                                    "delay": 2500,
                                                    "disableOnInteraction": false
                                                }}
                                                    breakpoints={{
                                                        0: {
                                                            slidesPerView: 2,
                                                        },
                                                        1109: {
                                                            slidesPerView: 2,
                                                        },
                                                        1100: {
                                                            slidesPerView: 3,
                                                        },
                                                        5600:{
                                                            slidesPerView: 3,
                                                        }
                                                    }}
                                                    loop={true}
                                                >
                                                    {item.SubTypes.map((sub) => {
                                                        return (
                                                            <>
                                                                <SwiperSlide key={sub.BookingTypeId}>
                                                                    <div className='row'>
                                                                        <div className='col-6 pe-0 mb-4' >
                                                                            <img src={variables.Photo_URL + sub.Image} className="w-100" alt={sub.Type} />
                                                                        </div>
                                                                        <div className='col-6 ps-0 mb-4'>
                                                                            <div className='specialInfo'>
                                                                                <h5>{sub.Type}</h5>
                                                                                <p>
                                                                                    {sub.Description}
                                                                                </p>
                                                                                <Link to="/SpecialOccassion" className='bookBtn'>
                                                                                    Book
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </SwiperSlide>
                                                            </>
                                                        )
                                                    })}
                                                </Swiper>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}

                        </div>
                        <div className='container-fluid d-md-none d-lg-none d-xl-none d-xs-block d-sm-block'>
                            {bookingTypes.filter((x) => x.BookingTypeId == 10).map((item) => {
                                return (
                                    <>
                                        <div className='row' key={item.BookingTypeId}>
                                            <div className='col-8 col-sm-8 col-md-6 col-lg-6 m-auto'>
                                                <h2>{item.Type}</h2>
                                            </div>
                                            <div className='col-4 col-sm-4 col-md-6 col-lg-6 text-right position-relative'>
                                                <Link to="/SpecialOccassion" className='viewallBtn'>
                                                    <span>
                                                        View All
                                                    </span>
                                                    <span>
                                                        <img src={rightLongArrow} alt="Arrow" />
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Swiper autoplay={{
                                                    "delay": 2500,
                                                    "disableOnInteraction": false
                                                }}
                                                    breakpoints={{
                                                        200: {
                                                            slidesPerView: 1
                                                        },
                                                        300: {
                                                            slidesPerView: 1
                                                        },
                                                        400: {
                                                            slidesPerView: 1,
                                                        },
                                                        600: {
                                                            slidesPerView: 2,
                                                        },
                                                        700: {
                                                            slidesPerView: 3,
                                                        },
                                                        800: {
                                                            slidesPerView: 3,
                                                        }
                                                    }}
                                                >
                                                    {item.SubTypes.map((sub) => {
                                                        return (
                                                            <>
                                                                <SwiperSlide key={sub.BookingTypeId}>
                                                                    <div className='specialInfoMobile' key={sub.BookingTypeId}>
                                                                        <div className='position-relative'>
                                                                            <img src={variables.Photo_URL + sub.Image} alt={sub.Type} className="w-100" />
                                                                            <h5>{sub.Type}</h5>
                                                                        </div>
                                                                        <Link to="/SpecialOccassion" className='bookBtnMobile'>
                                                                            Book a table
                                                                        </Link>
                                                                    </div>
                                                                </SwiperSlide>
                                                            </>
                                                        )
                                                    })}
                                                </Swiper>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className='packagesSection'>
                        <div className='container-fluid  d-none d-md-block d-lg-block d-xl-block'>
                            {bookingTypes.filter((x) => x.BookingTypeId == 11).map((item) => {
                                return (
                                    <>
                                        <div className='row justify-content-center' key={item.BookingTypeId}>
                                            <div className='col-12'>
                                                <h2>{item.Type}</h2>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Swiper autoplay={{
                                                    "delay": 2500,
                                                    "disableOnInteraction": false
                                                }}
                                                    breakpoints={{
                                                        0: {
                                                            slidesPerView: 2,
                                                        },
                                                        1109: {
                                                            slidesPerView: 2,
                                                        },
                                                        1100: {
                                                            slidesPerView: 3,
                                                        },
                                                        5600:{
                                                            slidesPerView: 3,
                                                        }
                                                    }}
                                                    loop={true}
                                                >
                                                    {item.SubTypes.map((sub) => {
                                                        return (
                                                            <>
                                                                <SwiperSlide key={sub.BookingTypeId}>
                                                                    <div className='row'>
                                                                        <div className='col-6 pe-0 mb-4' >
                                                                            <img src={variables.Photo_URL + sub.Image} className="w-100" alt={sub.Type} />
                                                                        </div>
                                                                        <div className='col-6 ps-0 mb-4'>
                                                                            <div className='packageInfo'>
                                                                                <h5>{sub.Type}</h5>
                                                                                <p>
                                                                                    {sub.Description}
                                                                                </p>
                                                                                <Link to="/EntertainmentPackages" className='bookBtn'>
                                                                                    Book
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </SwiperSlide>
                                                            </>
                                                        )
                                                    })}
                                                </Swiper>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                        <div className='container-fluid d-md-none d-lg-none d-xl-none d-xs-block d-sm-block'>
                            {bookingTypes.filter((x) => x.BookingTypeId == 10).map((item) => {
                                return (
                                    <>
                                        <div className='row' key={item.BookingTypeId}>
                                            <div className='col-8 col-sm-8 col-md-6 col-lg-6 m-auto'>
                                                <h2>{item.Type}</h2>
                                            </div>
                                            <div className='col-4 col-sm-4 col-md-6 col-lg-6 text-right position-relative'>
                                                <Link to="/EntertainmentPackages" className='viewallBtn'>
                                                    <span>
                                                        View All
                                                    </span>
                                                    <span>
                                                        <img src={rightLongArrow} alt="Arrow" />
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Swiper autoplay={{
                                                    "delay": 3500,
                                                    "disableOnInteraction": false
                                                }}
                                                    breakpoints={{
                                                        200: {
                                                            slidesPerView: 1
                                                        },
                                                        300: {
                                                            slidesPerView: 1
                                                        },
                                                        400: {
                                                            slidesPerView: 1,
                                                        },
                                                        600: {
                                                            slidesPerView: 2,
                                                        },
                                                        700: {
                                                            slidesPerView: 3,
                                                        },
                                                        800: {
                                                            slidesPerView: 3,
                                                        }
                                                    }}
                                                >
                                                    {item.SubTypes.map((sub) => {
                                                        return (
                                                            <>
                                                                <SwiperSlide key={sub.BookingTypeId}>
                                                                    <div className='packageInfoMobile' key={sub.BookingTypeId}>
                                                                        <div className='position-relative'>
                                                                            <img src={variables.Photo_URL + sub.Image} alt={sub.Type} className="w-100" />
                                                                            <h5>{sub.Type}</h5>
                                                                        </div>
                                                                        <Link to="/EntertainmentPackages" className='bookBtnMobile'>
                                                                            Book a table
                                                                        </Link>
                                                                    </div>
                                                                </SwiperSlide>
                                                            </>
                                                        )
                                                    })}
                                                </Swiper>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className='careersSection mb-sm-5'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-sm-12 col-lg-9 careersPadding'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <h2>CAREERS</h2>
                                        </div>
                                        <div className='col-8 m-auto'>
                                            <div className='careersLine'></div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 subTitleMargin'>
                                            <label className='subTitle'>why us</label>
                                            <br />
                                            <p className='careersPars'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla.</p>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                            <h2>CAREERS</h2>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 subTitleMargin'>
                                            <label className='subTitle'>open positions</label>
                                            <br />
                                            <Swiper slidesPerView={1}
                                                autoplay={{
                                                    "delay": 4500,
                                                    "disableOnInteraction": false
                                                }}
                                                breakpoints={{
                                                    0: {
                                                        slidesPerView: 1
                                                    },
                                                    300: {
                                                        slidesPerView: 1
                                                    },
                                                    400: {
                                                        slidesPerView: 1,
                                                    },
                                                    600: {
                                                        slidesPerView: 1,
                                                    },
                                                    700: {
                                                        slidesPerView: 2,
                                                    },
                                                    900: {
                                                        slidesPerView: 2,
                                                    },
                                                    1500: {
                                                        slidesPerView: 3,
                                                    }
                                                }}
                                                navigation={true}
                                                className="homePositionsCarousel"
                                            >
                                                <SwiperSlide>
                                                <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>
                                            {/* <div className='owl-carousel owl-theme homePositionsCarousel mt-3'>
                                                <div class='item'>
                                                    <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class='item'>
                                                    <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class='item'>
                                                    <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class='item'>
                                                    <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class='item'>
                                                    <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class='item'>
                                                    <div className='positionBox'>
                                                        <div className='row m-auto'>
                                                            <div className='col-12'>
                                                                <label className='dept'>Department</label>
                                                                <label className='title'>Cook</label>
                                                                <br />
                                                                <p className='text mt-3'>Lorem ipsum lorem ipsum ignie lorem si pisetg</p>
                                                            </div>
                                                            <button type='button' className='applyBtn'>Apply to job</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 d-none d-md-block d-lg-block d-xl-block'>
                                    <img src={careersArt} className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='contactSection d-none d-md-block d-lg-block d-xl-block'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-4'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462562.6509516108!2d54.94755498679835!3d25.07575945545691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2slb!4v1649758505875!5m2!1sen!2slb" height="470" style={{ border: 0, width: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                                <div className='col-4'>
                                    <div className='contactInfo'>
                                        <h3>Meet Us</h3>
                                        <p>
                                            <span>
                                                <img src={awesomePhoneAlt} alt="Phone" />
                                            </span>
                                            <span>
                                                +971 54 995 869
                                            </span>
                                        </p>
                                        <p>
                                            <span>
                                                <img src={featherMail} alt="Phone" />
                                            </span>
                                            <span>
                                                info@admin.com
                                            </span>
                                        </p>
                                        <p>
                                            <span>
                                                <img src={metrolocation} alt="Phone" />
                                            </span>
                                            <span>
                                                1233 park street, town, Dubai
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='contactForm'>
                                        <h3>Contact Us</h3>
                                        <input placeholder='Enter Email Here' />
                                        <textarea placeholder='Enter Message Here' rows="4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default Home;